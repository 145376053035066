import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { InvoiceSentMethod } from "../../../../../new/helpers";
import {
	CUSTOMERS_PAGE_SIZE,
	CUSTOMER_STATUSES,
	CUSTOMER_STATUS_MAPPINGS,
} from "../../../../common/models/src/lib/constants/customers.constants";
import { CUSTOMER_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../common/models/src/lib/constants/grid.constants";
import { INVOICE_FREQUENCY } from "../../../../common/models/src/lib/constants/invoice.constants";
import { NOT_AVAILABLE } from "../../../../common/models/src/lib/constants/messages.constants";
import { FilterItemType } from "../../../../common/models/src/lib/enums/filter-item-type.enum";
import { InvoiceTypeMethod } from "../../../../common/models/src/lib/enums/invoice.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import { TemplateType } from "../../../../common/models/src/lib/enums/template.enum";
import type { ICustomer } from "../../../../common/models/src/lib/interfaces/customer.interface";
import type { IFilterItem } from "../../../../common/models/src/lib/interfaces/filter.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumWhiteButton } from "../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import {
	fetchAllChecklistsAction,
	fetchAllTemplatesByTypeAction,
} from "../../../../invoices/data-access/src/lib/invoices.slice";
import {
	clearCustomersAction,
	deleteCustomerAction,
	fetchCustomersAction,
	syncWithSageAction,
} from "../../../data-access/src/lib/customers.slice";
import { CustomerForm } from "./components/customer-form";

interface CustomerFilterState {
	currencyFilter: IFilterItem[];
	statusFilter: IFilterItem[];
}

const initialFilterState: CustomerFilterState = {
	currencyFilter: [],
	statusFilter: [],
};

export const CustomersFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading, syncedWithSage } = useAppSelector(
		(state) => state.customers,
	);
	const { data: currencies } = useAppSelector((state) => state.currencies);

	const [modalVisible, setModalVisible] = useState<{
		delete: boolean;
		syncWithSage: boolean;
		edit: boolean;
	}>({
		delete: false,
		edit: false,
		syncWithSage: false,
	});
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedCustomer, setSelectedCustomer] = useState<
		ICustomer | undefined
	>(undefined);
	const [filters, setFilters] = useState<string>("");
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({
		skip: 0,
		take: CUSTOMERS_PAGE_SIZE,
	});

	const [customerFilters, setCustomerFilters] = useOldLocalStorage(
		"customerFilterState",
		initialFilterState,
	);

	const renderedCustomers = useMemo(
		() =>
			data.map((customer) => ({
				...customer,
				currencyCode: customer.currency?.code ?? NOT_AVAILABLE,
				primaryPhone: customer.phones?.length ? customer.phones[0] : "",
				primaryEmail: customer.emails?.length ? customer.emails[0] : "",
				invoiceFrequency: INVOICE_FREQUENCY[customer.invoiceFrequency],
				invoiceType: InvoiceTypeMethod[customer.invoiceType],
				invoiceSentMethod: InvoiceSentMethod[customer.invoiceSentMethod],
			})),
		[data],
	);

	const requestSortedData = (sort: string) => {
		setSort(sort);
		dispatch(clearCustomersAction());
		dispatchWithNotifications({
			action: fetchCustomersAction,
			payload: { page: 1, pageSize: CUSTOMERS_PAGE_SIZE, sort, filters },
			errorMessage: "Could not sort customers.",
		});
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / CUSTOMERS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchCustomersAction,
					payload: { page, pageSize: CUSTOMERS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch customers.",
				});
				break;
			}
		}
	};

	const handleSyncWithSage = async () => {
		const actionResult = await dispatchWithNotifications({
			action: syncWithSageAction,
			errorMessage: "Could not sync with Sage.",
			successMessage: "Synchronisation with Sage completed successfully.",
		});

		if (syncWithSageAction.fulfilled.match(actionResult)) {
			dispatchWithNotifications({
				action: fetchCustomersAction,
				errorMessage: "Could not fetch customers.",
			});
		}
	};

	const handleDeleteCustomer = () => {
		if (!selectedCustomer) return;
		dispatchWithNotifications({
			action: deleteCustomerAction,
			payload: selectedCustomer.id,
			successMessage: `Customer ${selectedCustomer.name} was successfully deleted.`,
			errorMessage: `Could not delete customer ${selectedCustomer.name}.`,
		});
		setSelectedCustomer(undefined);
	};

	const openInsertDialog = (): void => {
		setSelectedCustomer(undefined);
		setModalVisible({ delete: false, syncWithSage: false, edit: true });
	};

	const openEditDialog = (customer: ICustomer) => {
		setModalVisible((prev) => ({ ...prev, edit: true }));
		setSelectedCustomer(customer);
	};

	const openDeleteDialog = (customer: ICustomer) => {
		setModalVisible((prev) => ({ ...prev, delete: true }));
		setSelectedCustomer(customer);
	};

	const clearFilters = () => {
		setCustomerFilters(initialFilterState);
	};

	useEffect(() => {
		const typesFilterLists = [];

		customerFilters.currencyFilter.length &&
			typesFilterLists.push(customerFilters.currencyFilter);
		customerFilters.statusFilter.length &&
			typesFilterLists.push(customerFilters.statusFilter);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"name",
				"accountCode",
				"address",
				"checklist.name",
				"currency.code",
				"invoiceTemplate",
			],
			[],
			typesFilterLists,
		);
		setFilters(filters);

		const payload = {
			page: 1,
			pageSize: CUSTOMERS_PAGE_SIZE,
			...(searchFilter || typesFilterLists.length ? { filters } : {}),
		};

		dispatchWithNotifications({
			action: fetchCustomersAction,
			payload,
			errorMessage: "Could not fetch customers.",
		});

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });

		return () => {
			dispatch(clearCustomersAction());
		};
	}, [customerFilters, searchFilter, dispatchWithNotifications, dispatch]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchAllChecklistsAction,
			errorMessage: "Could not fetch invoice checklists.",
		});
		dispatchWithNotifications({
			action: fetchAllTemplatesByTypeAction,
			payload: TemplateType.INVOICE,
			errorMessage: "Could not fetch invoice templates.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Customers"
				numberOfItems={total}
				addButtonName={"Add Customer"}
				addButtonDisabled={loading}
				handleOpenAddModal={openInsertDialog}
			>
				<>
					<CelerumFilters
						setSearchFilter={setSearchFilter}
						setFilters={setCustomerFilters}
						initialValues={{
							statusFilter: customerFilters.statusFilter,
							currencyFilter: customerFilters.currencyFilter,
						}}
						filters={{
							statusFilter: {
								name: "Status",
								column: "status",
								values: CUSTOMER_STATUSES,
								type: FilterItemType.DROPDOWN,
							},
							currencyFilter: {
								name: "Currency",
								column: "currency.id",
								values: currencies,
								type: FilterItemType.DROPDOWN,
							},
						}}
						clearFilters={clearFilters}
					/>
					<CelerumWhiteButton
						title="Sync with Sage"
						style={{ width: "130px", marginLeft: "32px" }}
						onClick={() =>
							setModalVisible((prev) => ({ ...prev, syncWithSage: true }))
						}
					/>
				</>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={CUSTOMER_GRID_COLUMNS}
				data={renderedCustomers}
				total={total}
				loading={loading}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleDelete={openDeleteDialog}
				handleUpdate={openEditDialog}
				statusMappings={CUSTOMER_STATUS_MAPPINGS}
				canDelete={!syncedWithSage}
			/>

			<CelerumModal
				title={selectedCustomer ? selectedCustomer.name : "Add New Customer"}
				width={ModalSize.Large}
				toggleDialog={() =>
					setModalVisible((prev) => ({ ...prev, edit: false }))
				}
				visible={modalVisible.edit}
			>
				<CustomerForm
					formState={selectedCustomer}
					syncedWithSage={selectedCustomer ? syncedWithSage : false}
					closeModal={() =>
						setModalVisible((prev) => ({ ...prev, edit: false }))
					}
				/>
			</CelerumModal>

			<CelerumConfirmModal
				type={modalVisible.delete ? ModalType.Delete : ModalType.Warning}
				title={
					modalVisible.syncWithSage
						? "Are you sure you want to sync customers with Sage?"
						: ""
				}
				subtitle={
					modalVisible.delete
						? "The customer will be completely removed and cannot be recovered."
						: "This action cannot be reverted."
				}
				isOpen={modalVisible.delete || modalVisible.syncWithSage}
				entityName="customer"
				entityProperty="name"
				entityValue={selectedCustomer?.name}
				handleSubmit={
					modalVisible.delete ? handleDeleteCustomer : handleSyncWithSage
				}
				handleClose={() => {
					setModalVisible({ delete: false, edit: false, syncWithSage: false });
					setSelectedCustomer(undefined);
				}}
			/>
		</>
	);
};
