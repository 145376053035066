import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { DRIVERS_PAGE_SIZE } from "../../../../common/models/src/lib/constants/driver.constants";
import { USER_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../common/models/src/lib/constants/grid.constants";
import {
	USERS_PAGE_SIZE,
	USER_STATUSES,
	USER_STATUS_MAPPINGS,
} from "../../../../common/models/src/lib/constants/user.constants";
import { FilterItemType } from "../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { IDriver } from "../../../../common/models/src/lib/interfaces/driver.interface";
import type { IFilterItem } from "../../../../common/models/src/lib/interfaces/filter.interface";
import type { IUserRequestDto } from "../../../../common/models/src/lib/interfaces/user.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import {
	fetchDriversAction,
	fetchNotLinkedDriversByUserIdAction,
} from "../../../../drivers/data-access/src/lib/drivers.slice";
import {
	deleteUserAction,
	fetchUsersAction,
} from "../../../data-access/src/lib/users.slice";
import { UserForm } from "./components/user-form/user-form.component";

interface UserFilterState {
	statusFilter: IFilterItem[];
	roleFilter: IFilterItem[];
}

const initialFilterState: UserFilterState = {
	statusFilter: [],
	roleFilter: [],
};

export const UsersFeature = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, roles, loading } = useAppSelector(
		(state) => state.users,
	);
	const { data: drivers } = useAppSelector((state) => state.drivers);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedUser, setSelectedUser] = useState<IUserRequestDto | undefined>(
		undefined,
	);
	const [userFilters, setUserFilters] = useOldLocalStorage<UserFilterState>(
		"userFilterState",
		initialFilterState,
	);
	const [page, setPage] = useState<Page>({ skip: 0, take: USERS_PAGE_SIZE });
	const [unlinkedDrivers, setUnlinkedDrivers] = useState<IDriver[]>([]);
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [filters, setFilters] = useState<string>("");

	const renderedUsers = useMemo(
		() =>
			data.map((user) =>
				user.driverId
					? {
							...user,
							role: user.roles[0]?.name,
							active: USER_STATUSES.find((item) => item.id === user.status),
							driver: drivers.find((driver) => user.driverId === driver.id),
						}
					: {
							...user,
							role: user.roles[0]?.name,
							active: USER_STATUSES.find((item) => item.id === user.status),
						},
			),
		[data, drivers],
	);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (user: IUserRequestDto) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedUser(user);
	};

	const openDeleteModal = (user: IUserRequestDto) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedUser(user);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedUser(undefined);
	};

	const handleDeleteUser = () => {
		if (!selectedUser) return;

		dispatchWithNotifications({
			action: deleteUserAction,
			payload: selectedUser.id,
			successMessage: `User ${selectedUser.fullName} successfully deleted.`,
			errorMessage: `Could not delete user ${selectedUser.fullName}!`,
		});
		setSelectedUser(undefined);
	};

	const clearFilters = () => {
		setUserFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / USERS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchUsersAction,
					payload: { page, pageSize: USERS_PAGE_SIZE, filters },
					errorMessage: "Could not fetch users.",
				});
				break;
			}
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchDriversAction,
			payload: { page: 1, pageSize: DRIVERS_PAGE_SIZE },
			errorMessage: "Could not fetch drivers.",
		});
	}, [dispatchWithNotifications]);

	useEffect(() => {
		const renderUnlinkedDrivers = async () => {
			if (renderedUsers.length) {
				const actionResult = await dispatchWithNotifications({
					action: fetchNotLinkedDriversByUserIdAction,
					payload: renderedUsers[0]?.id,
					errorMessage: "Could not fetch drivers.",
				});

				if (fetchNotLinkedDriversByUserIdAction.fulfilled.match(actionResult)) {
					setUnlinkedDrivers(actionResult.payload.notLinkedDrivers);
				}
			}
		};

		renderUnlinkedDrivers();
	}, [dispatchWithNotifications, renderedUsers]);

	useEffect(() => {
		const typesFilterLists = [];

		userFilters.statusFilter.length &&
			typesFilterLists.push(userFilters.statusFilter);

		const filters = buildFilterQueryString(
			searchFilter,
			["fullName", "email"],
			undefined,
			typesFilterLists,
			userFilters.roleFilter,
		);

		setFilters(filters);

		const payload = {
			page: 1,
			pageSize: USERS_PAGE_SIZE,
			...(typesFilterLists.length ||
			searchFilter ||
			userFilters.roleFilter?.length
				? { filters }
				: {}),
		};

		dispatchWithNotifications({
			action: fetchUsersAction,
			payload,
			errorMessage: "Could not fetch users.",
		});

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [userFilters, searchFilter, dispatchWithNotifications]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	return (
		<>
			<CelerumGridHeader
				title="Users"
				numberOfItems={total}
				addButtonName="Add User"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setFilters={setUserFilters}
					setSearchFilter={setSearchFilter}
					initialValues={{
						statusFilter: userFilters.statusFilter,
						roleFilter: userFilters.roleFilter,
					}}
					filters={{
						statusFilter: {
							name: "Status",
							column: "status",
							values: USER_STATUSES,
							type: FilterItemType.DROPDOWN,
						},
						roleFilter: {
							name: "Role",
							column: "roleIds",
							values: roles,
							type: FilterItemType.DROPDOWN,
						},
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={USER_GRID_COLUMNS}
				data={renderedUsers}
				total={total}
				loading={loading}
				requestDataIfNeeded={requestDataIfNeeded}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				canDelete={false}
				statusMappings={USER_STATUS_MAPPINGS}
			/>
			<CelerumModal
				title={
					selectedUser
						? `${selectedUser.firstName} ${selectedUser.lastName}`
						: "Add New User"
				}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<UserForm
					unlinkedDrivers={unlinkedDrivers}
					formState={selectedUser}
					onClose={closeModal}
				/>
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The user will be completely removed and cannot be recovered."
				entityName="user"
				entityProperty="named"
				entityValue={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteUser}
				handleClose={closeModal}
			/>
		</>
	);
};
