import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { camelCase } from "lodash";
import {
	LEGS_FEATURE_KEY,
	LEG_TYPES,
	NEW_LEG_STATE,
} from "../../../../common/models/src/lib/constants/leg.constants";
import { LegStatus } from "../../../../common/models/src/lib/enums/leg.enum";
import type { IAttachment } from "../../../../common/models/src/lib/interfaces/attachment.interface";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type {
	CelerumResponse,
	CelerumSummaryResponse,
} from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { IDocument } from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	AddLegAttachmentDto,
	IGenerateCollectionNoteRequestDto,
	ILegData,
	ILegSummary,
	LegsState,
} from "../../../../common/models/src/lib/interfaces/leg.interface";
import type {
	AnyLeg,
	AnyLegRequestDto,
} from "../../../../common/models/src/lib/types/leg.type";
import { updateLegStateData } from "../../../../common/utils/src/lib/helpers/state.helpers";
import {
	changeLegStatus,
	createLeg,
	deleteLeg,
	fetchLegSummary,
	fetchLegsByJobId,
	fetchLegsByLoadId,
	generateCollectionNote,
	generateDeliveryTicket,
	generateSubcontractorOrder,
	updateLeg,
	updateLegSupplierInvoiceNumber,
} from "./legs-data-access";

const initialState: LegsState = {
	data: {},
	summary: [],
	total: 0,
	loading: false,
};

export const fetchLegsByJobIdAction = createAsyncThunk(
	"legs/fetchLegsByJobId",
	(jobId: number) => {
		return fetchLegsByJobId(jobId);
	},
);

export const fetchLegSummaryAction = createAsyncThunk(
	"legs/fetchLegSummary",
	(params: CelerumQueryParams) => {
		return fetchLegSummary(params);
	},
);

export const fetchLegsByLoadIdAction = createAsyncThunk(
	"legs/fetchLegsByLoadId",
	(loadId: number) => {
		return fetchLegsByLoadId(loadId);
	},
);

export const createLegAction = createAsyncThunk(
	"legs/createLeg",
	(leg: AnyLegRequestDto) => {
		return createLeg(leg);
	},
);

export const updateLegAction = createAsyncThunk(
	"legs/updateLeg",
	(leg: AnyLegRequestDto) => {
		return updateLeg(leg);
	},
);

export const updateLegSupplierInvoiceNumberAction = createAsyncThunk(
	"legs/updateLegSupplierInvoiceNumber",
	({
		legId,
		supplierInvoiceNumber,
		supplierInvoiceDate,
	}: {
		legId: number;
		supplierInvoiceNumber: string;
		supplierInvoiceDate: Date;
	}) => {
		return updateLegSupplierInvoiceNumber(
			legId,
			supplierInvoiceNumber,
			dayjs(supplierInvoiceDate).format("YYYY-MM-DD HH:mm:ss"),
		);
	},
);

export const changeLegStatusAction = createAsyncThunk(
	"legs/changeLegStatus",
	({ legId, status }: { legId: number; status: number }) => {
		return changeLegStatus(legId, status);
	},
);

export const deleteLegAction = createAsyncThunk(
	"legs/deleteLeg",
	(legId: number) => {
		return deleteLeg(legId);
	},
);

export const generateCollectionNoteAction = createAsyncThunk(
	"legs/generateCollectionNote",
	(dto: IGenerateCollectionNoteRequestDto) => {
		return generateCollectionNote(dto);
	},
);

export const generateDeliveryTicketAction = createAsyncThunk(
	"legs/generateDeliveryTicket",
	(dto: IGenerateCollectionNoteRequestDto) => {
		return generateDeliveryTicket(dto);
	},
);

export const generateSubcontractorOrderAction = createAsyncThunk(
	"legs/generateSubcontractorOrder",
	(dto: IGenerateCollectionNoteRequestDto) => {
		return generateSubcontractorOrder(dto);
	},
);

const legsSlice = createSlice({
	name: LEGS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearLegsAction: () => initialState,
		attachDocumentsToLegAction: (
			state: LegsState,
			action: PayloadAction<AddLegAttachmentDto>,
		) => {
			for (const [key, array] of Object.entries(state.data)) {
				if (!array.length) continue;
				state.data[key as keyof ILegData]?.map((item: AnyLeg) => {
					if (item.id === action.payload.legId) {
						item.documents = [...item.documents, ...action.payload.documents];
					}
				});
			}
		},
		deleteLegAttachmentAction: (
			state: LegsState,
			action: PayloadAction<IAttachment | null>,
		) => {
			for (const [key, array] of Object.entries(state.data)) {
				if (array.length) {
					state.data[key as keyof ILegData]?.map((item: AnyLeg) => {
						item.documents = item.documents.filter(
							(document: IDocument) => document.id !== action.payload?.id,
						);
					});
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchLegsByJobIdAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(fetchLegsByLoadIdAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(fetchLegSummaryAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(createLegAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(updateLegAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(changeLegStatusAction.pending, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(deleteLegAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(generateCollectionNoteAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(generateDeliveryTicketAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			.addCase(generateSubcontractorOrderAction.pending, (state: LegsState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchLegsByJobIdAction.fulfilled,
				(state: LegsState, action: PayloadAction<CelerumSummaryResponse>) => {
					state.data = action.payload as ILegData;

					for (const [key, array] of Object.entries(state.data)) {
						state.data[key as keyof ILegData] = array.map((leg: AnyLeg) => {
							if ("collectionLocation" in leg) {
								return {
									...leg,
									collectionLocation: leg.collectionLocation && {
										...leg.collectionLocation,
										nameAndAddress: `${leg.collectionLocation.name} - ${leg.collectionLocation.address}`,
									},
									deliveryLocation: leg.deliveryLocation && {
										...leg.deliveryLocation,
										nameAndAddress: `${leg.deliveryLocation.name} - ${leg.deliveryLocation.address}`,
									},
								};
							}
							return leg;
						});
					}

					state.loading = false;
				},
			)
			.addCase(
				fetchLegsByLoadIdAction.fulfilled,
				(state: LegsState, action: PayloadAction<CelerumSummaryResponse>) => {
					state.data = action.payload as ILegData;

					for (const [key, array] of Object.entries(state.data)) {
						state.data[key as keyof ILegData] = array.map((leg: AnyLeg) => {
							if ("collectionLocation" in leg) {
								return {
									...leg,
									collectionLocation: leg.collectionLocation && {
										...leg.collectionLocation,
										nameAndAddress: `${leg.collectionLocation.name} - ${leg.collectionLocation.address}`,
									},
									deliveryLocation: leg.deliveryLocation && {
										...leg.deliveryLocation,
										nameAndAddress: `${leg.deliveryLocation.name} - ${leg.deliveryLocation.address}`,
									},
								};
							}
							return leg;
						});
					}

					state.loading = false;
				},
			)
			.addCase(
				createLegAction.fulfilled,
				(state: LegsState, action: PayloadAction<AnyLeg | null>) => {
					const leg = action.payload as AnyLeg | null;

					if (!leg) {
						return;
					}

					const legTypeName =
						camelCase(LEG_TYPES.find((item) => item.id === leg.type)?.name) ||
						"invalid";

					/** Call the helper function */
					updateLegStateData(state, legTypeName, leg, "create");

					state.loading = false;
				},
			)
			.addCase(
				updateLegAction.fulfilled,
				(state: LegsState, action: PayloadAction<AnyLeg | null>) => {
					const leg = action.payload as AnyLeg | null;

					if (!leg) {
						return;
					}

					const legTypeName =
						camelCase(LEG_TYPES.find((item) => item.id === leg.type)?.name) ||
						"invalid";

					/** Call the helper function */
					updateLegStateData(state, legTypeName, leg, "update");

					state.loading = false;
				},
			)
			.addCase(
				changeLegStatusAction.fulfilled,
				(
					state: LegsState,
					action: PayloadAction<{
						legId: number;
						status: number;
					} | null>,
				) => {
					const { legId, status } = action.payload as {
						legId: number;
						status: number;
					};

					for (const [, array] of Object.entries(state.data)) {
						if (array.length) {
							const legIndex = array.findIndex(
								(leg: AnyLeg) => leg.id === legId,
							);

							if (legIndex !== -1) {
								array[legIndex].status = status;
							}
						}
					}

					const summaryLegIndex = state.summary.findIndex(
						(leg: ILegSummary) => leg.id === legId,
					);
					if (summaryLegIndex !== -1) {
						const leg = state.summary[summaryLegIndex];
						if (leg != null) leg.status = status;
						if (status === LegStatus.Cancelled) {
							state.summary = state.summary.filter(
								(leg: ILegSummary) => leg.id !== legId,
							);
						}
					}
					state.loading = false;
				},
			)
			.addCase(
				deleteLegAction.fulfilled,
				(state: LegsState, action: PayloadAction<number | null>) => {
					for (const [key, array] of Object.entries(state.data)) {
						if (array.length) {
							state.data[key as keyof ILegData] = array.filter(
								(leg: AnyLeg) => leg.id !== action.payload,
							);
						}
					}

					state.summary = state.summary.filter(
						(leg: ILegSummary) => leg.id !== action.payload,
					);

					state.loading = false;
				},
			)
			.addCase(
				fetchLegSummaryAction.fulfilled,
				(state: LegsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, page, pageSize } = action.payload;
					const legSummaries = data as ILegSummary[];
					const newLegSummaries =
						state.summary.length === total
							? [...state.summary]
							: new Array(total).fill(NEW_LEG_STATE);

					/** Fill newGoods with the fetched data */
					legSummaries.forEach((summary, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newLegSummaries[index] = {
								...newLegSummaries[index],
								...summary,
							};
						}
					});

					state.summary = newLegSummaries;
					state.total = action.payload.total;
					state.loading = false;
				},
			)
			.addCase(generateCollectionNoteAction.fulfilled, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(generateDeliveryTicketAction.fulfilled, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(
				generateSubcontractorOrderAction.fulfilled,
				(state: LegsState) => {
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(fetchLegsByJobIdAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(fetchLegsByLoadIdAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(createLegAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(updateLegAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(changeLegStatusAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(deleteLegAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(fetchLegSummaryAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(generateCollectionNoteAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(generateDeliveryTicketAction.rejected, (state: LegsState) => {
				state.loading = false;
			})
			.addCase(
				generateSubcontractorOrderAction.rejected,
				(state: LegsState) => {
					state.loading = false;
				},
			);
	},
});

/** Reducer */
export const legsReducer = legsSlice.reducer;

export const {
	attachDocumentsToLegAction,
	deleteLegAttachmentAction,
	clearLegsAction,
} = legsSlice.actions;
