import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { attachmentsReducer } from "../../../../attachments/data-access/src/lib/attachments.slice";
import { authenticationReducer } from "../../../../authentication/data-access/src/lib/authentication.slice";
import { businessUnitsReducer } from "../../../../business-units/data-access/src/lib/business-units.slice";
import { constraintsReducer } from "../../../../constraints/data-access/src/lib/constraints.slice";
import { currenciesReducer } from "../../../../currencies/data-access/src/lib/currencies.slice";
import { currencyExchangeRatesReducer } from "../../../../currencies/data-access/src/lib/currency-exchange-rate.slice";
import { customersReducer } from "../../../../customers/data-access/src/lib/customers.slice";
import { driversReducer } from "../../../../drivers/data-access/src/lib/drivers.slice";
import { goodsReducers } from "../../../../goods/data-access/src/lib/goods.slice";
import { invoicesReducer } from "../../../../invoices/data-access/src/lib/invoices.slice";
import { jobTypesReducer } from "../../../../jobs/data-access/src/lib/job-types.slice";
import { jobsReducer } from "../../../../jobs/data-access/src/lib/jobs.slice";
import { legsReducer } from "../../../../legs/data-access/src/lib/legs.slice";
import { loadTypesReducer } from "../../../../loads/data-access/src/lib/load-types.slice";
import { loadsReducer } from "../../../../loads/data-access/src/lib/loads.slice";
import { locationsReducer } from "../../../../locations/data-access/src/lib/locations.slice";
import { organisationsReducer } from "../../../../organisations/data-access/src/lib/organisations.slice";
import { subcontractorsReducer } from "../../../../subcontractors/data-access/src/lib/subcontractors.slice";
import { trailerTypesReducer } from "../../../../trailers/data-access/src/lib/trailer-types.slice";
import { trailersReducer } from "../../../../trailers/data-access/src/lib/trailers.slice";
import { truckTypesReducer } from "../../../../trucks/data-access/src/lib/truck-types.slice";
import { trucksReducer } from "../../../../trucks/data-access/src/lib/trucks.slice";
import { userInterfaceReducer } from "../../../../user-interface/data-access/src/lib/user-interface.slice";
import { usersReducer } from "../../../../users/data-access/src/lib/users.slice";
import { ATTACHMENTS_FEATURE_KEY } from "../../../models/src/lib/constants/attachment.constants";
import { AUTHENTICATION_FEATURE_KEY } from "../../../models/src/lib/constants/authentication.constants";
import { BUSINESS_UNITS_FEATURE_KEY } from "../../../models/src/lib/constants/business-unit.constants";
import { CONSTRAINTS_FEATURE_KEY } from "../../../models/src/lib/constants/constraints.constants";
import {
	CURRENCIES_FEATURE_KEY,
	CURRENCY_EXCHANGE_RATES_FEATURE_KEY,
} from "../../../models/src/lib/constants/currencies.constants";
import { CUSTOMERS_FEATURE_KEY } from "../../../models/src/lib/constants/customers.constants";
import { DRIVERS_FEATURE_KEY } from "../../../models/src/lib/constants/driver.constants";
import { GOODS_FEATURE_KEY } from "../../../models/src/lib/constants/goods.constants";
import { INVOICES_FEATURE_KEY } from "../../../models/src/lib/constants/invoice.constants";
import { JOB_TYPES_FEATURE_KEY } from "../../../models/src/lib/constants/job-types.constants";
import { JOBS_FEATURE_KEY } from "../../../models/src/lib/constants/job.constants";
import { LEGS_FEATURE_KEY } from "../../../models/src/lib/constants/leg.constants";
import { LOAD_TYPES_FEATURE_KEY } from "../../../models/src/lib/constants/load-types.constants";
import { LOADS_FEATURE_KEY } from "../../../models/src/lib/constants/load.constants";
import { LOCATIONS_FEATURE_KEY } from "../../../models/src/lib/constants/location.constants";
import { ORGANISATIONS_FEATURE_KEY } from "../../../models/src/lib/constants/organisation.constants";
import { SUBCONTRACTORS_FEATURE_KEY } from "../../../models/src/lib/constants/subcontractor.constants";
import { TRAILER_TYPES_FEATURE_KEY } from "../../../models/src/lib/constants/trailer-types.constants";
import { TRAILERS_FEATURE_KEY } from "../../../models/src/lib/constants/trailer.constants";
import { TRUCK_TYPES_FEATURE_KEY } from "../../../models/src/lib/constants/truck-types.constants";
import { TRUCKS_FEATURE_KEY } from "../../../models/src/lib/constants/truck.constants";
import { USER_INTERFACE_FEATURE_KEY } from "../../../models/src/lib/constants/user-interface.constants";
import { USERS_FEATURE_KEY } from "../../../models/src/lib/constants/user.constants";

const authPersistConfig = {
	key: "authentication",
	storage,
};

const currencyPersistConfig = {
	key: "currency",
	storage,
};

const persistedAuthenticationReducer = persistReducer(
	authPersistConfig,
	authenticationReducer,
);
const persistedCurrencyReducer = persistReducer(
	currencyPersistConfig,
	currenciesReducer,
);

export const clientStore = configureStore({
	reducer: {
		[AUTHENTICATION_FEATURE_KEY]: persistedAuthenticationReducer,
		[JOBS_FEATURE_KEY]: jobsReducer,
		[INVOICES_FEATURE_KEY]: invoicesReducer,
		[TRUCKS_FEATURE_KEY]: trucksReducer,
		[TRAILERS_FEATURE_KEY]: trailersReducer,
		[CUSTOMERS_FEATURE_KEY]: customersReducer,
		[TRUCK_TYPES_FEATURE_KEY]: truckTypesReducer,
		[JOB_TYPES_FEATURE_KEY]: jobTypesReducer,
		[DRIVERS_FEATURE_KEY]: driversReducer,
		[CONSTRAINTS_FEATURE_KEY]: constraintsReducer,
		[SUBCONTRACTORS_FEATURE_KEY]: subcontractorsReducer,
		[CURRENCIES_FEATURE_KEY]: persistedCurrencyReducer,
		[CURRENCY_EXCHANGE_RATES_FEATURE_KEY]: currencyExchangeRatesReducer,
		[TRAILER_TYPES_FEATURE_KEY]: trailerTypesReducer,
		[GOODS_FEATURE_KEY]: goodsReducers,
		[LOCATIONS_FEATURE_KEY]: locationsReducer,
		[LEGS_FEATURE_KEY]: legsReducer,
		[USERS_FEATURE_KEY]: usersReducer,
		[BUSINESS_UNITS_FEATURE_KEY]: businessUnitsReducer,
		[ATTACHMENTS_FEATURE_KEY]: attachmentsReducer,
		[USER_INTERFACE_FEATURE_KEY]: userInterfaceReducer,
		[LOAD_TYPES_FEATURE_KEY]: loadTypesReducer,
		[LOADS_FEATURE_KEY]: loadsReducer,
		[ORGANISATIONS_FEATURE_KEY]: organisationsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				/** Ignore redux-persist actions and pdf generating action */
				ignoredActions: [
					"persist/PERSIST",
					"persist/REHYDRATE",
					"loads/generateManifest/fulfilled",
					"invoices/getPreview/fulfilled",
				],
			},
		}),
});

export const clientPersistor = persistStore(clientStore);

export type ClientState = {
	[AUTHENTICATION_FEATURE_KEY]: ReturnType<typeof authenticationReducer>;
	[JOBS_FEATURE_KEY]: ReturnType<typeof jobsReducer>;
	[INVOICES_FEATURE_KEY]: ReturnType<typeof invoicesReducer>;
	[TRUCKS_FEATURE_KEY]: ReturnType<typeof trucksReducer>;
	[TRAILERS_FEATURE_KEY]: ReturnType<typeof trailersReducer>;
	[CUSTOMERS_FEATURE_KEY]: ReturnType<typeof customersReducer>;
	[TRUCK_TYPES_FEATURE_KEY]: ReturnType<typeof truckTypesReducer>;
	[JOB_TYPES_FEATURE_KEY]: ReturnType<typeof jobTypesReducer>;
	[DRIVERS_FEATURE_KEY]: ReturnType<typeof driversReducer>;
	[CONSTRAINTS_FEATURE_KEY]: ReturnType<typeof constraintsReducer>;
	[SUBCONTRACTORS_FEATURE_KEY]: ReturnType<typeof subcontractorsReducer>;
	[CURRENCIES_FEATURE_KEY]: ReturnType<typeof currenciesReducer>;
	[CURRENCY_EXCHANGE_RATES_FEATURE_KEY]: ReturnType<
		typeof currencyExchangeRatesReducer
	>;
	[TRAILER_TYPES_FEATURE_KEY]: ReturnType<typeof trailerTypesReducer>;
	[GOODS_FEATURE_KEY]: ReturnType<typeof goodsReducers>;
	[LOCATIONS_FEATURE_KEY]: ReturnType<typeof locationsReducer>;
	[LEGS_FEATURE_KEY]: ReturnType<typeof legsReducer>;
	[USERS_FEATURE_KEY]: ReturnType<typeof usersReducer>;
	[BUSINESS_UNITS_FEATURE_KEY]: ReturnType<typeof businessUnitsReducer>;
	[ATTACHMENTS_FEATURE_KEY]: ReturnType<typeof attachmentsReducer>;
	[USER_INTERFACE_FEATURE_KEY]: ReturnType<typeof userInterfaceReducer>;
	[LOAD_TYPES_FEATURE_KEY]: ReturnType<typeof loadTypesReducer>;
	[LOADS_FEATURE_KEY]: ReturnType<typeof loadsReducer>;
	[ORGANISATIONS_FEATURE_KEY]: ReturnType<typeof organisationsReducer>;
};

export type ClientAppDispatch = typeof clientStore.dispatch;
