import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { AUTHENTICATION_FEATURE_KEY } from "../../../../common/models/src/lib/constants/authentication.constants";
import { LEG_TYPES } from "../../../../common/models/src/lib/constants/leg.constants";
import { ErrorCode } from "../../../../common/models/src/lib/enums/error-codes.enum";
import { NotificationType } from "../../../../common/models/src/lib/enums/notification-type.enum";
import type {
	AuthenticationState,
	IActivateAccount,
	IAuthentication,
	IResetPassword,
} from "../../../../common/models/src/lib/interfaces/authentication.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { IUser } from "../../../../common/models/src/lib/interfaces/user.interface";
import {
	generateErrorCode,
	generateErrorMessage,
} from "../../../../common/utils/src/lib/helpers/error.helpers";
import {
	activateAccount,
	fetchBusinessUnit,
	fetchUsersByRoleName,
	forgotPassword,
	login,
	logout,
	resendActivationEmail,
	resetPassword,
} from "./authentication-data-access";

const initialState: AuthenticationState = {
	token: undefined,
	expiryTokenValue: undefined,
	userId: undefined,
	users: [],
	availableLegTypes: [],
	availableStageTypes: [],
	businessUnits: undefined,
	currentUser: undefined,
	currentBusinessUnit: undefined,
	loading: false,
	errorCode: ErrorCode.DEFAULT,
	notificationType: null,
	message: "",
};

export const loginAction = createAsyncThunk(
	"authentication/login",
	async (data: IAuthentication) => {
		return login(data.username, data.password);
	},
);

export const logoutAction = createAsyncThunk(
	"authentication/logout",
	async () => {
		return logout();
	},
);

export const forgotPasswordAction = createAsyncThunk(
	"authentication/forgotPassword",
	async (email: string) => {
		return forgotPassword(email);
	},
);

export const fetchUsersByRoleNameAction = createAsyncThunk(
	"users/fetchUsersByRoleName",
	async (types: string[]) => {
		return fetchUsersByRoleName(types);
	},
);

export const resetPasswordAction = createAsyncThunk(
	"authentication/resetPassword",
	async (data: IResetPassword) => {
		return resetPassword(data.email, data.password, data.token);
	},
);

export const accountActivationAction = createAsyncThunk(
	"authentication/accountConfirmation",
	async (accountActivationItem: IActivateAccount) => {
		return activateAccount(accountActivationItem);
	},
);

export const resendActivationEmailAction = createAsyncThunk(
	"authentication/resendActivationEmail",
	(email: string) => {
		return resendActivationEmail(email);
	},
);

export const fetchBusinessUnitAction = createAsyncThunk(
	"authentication/fetchBusinessUnit",
	async (businessUnitId: string) => {
		return fetchBusinessUnit(businessUnitId);
	},
);

const authenticationSlice = createSlice({
	name: AUTHENTICATION_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		resetAuthenticationNotificationAction: (state: AuthenticationState) => {
			state.notificationType = initialState.notificationType;
			state.message = initialState.message;
			state.errorCode = ErrorCode.DEFAULT;
		},
		selectBusinessUnitAction: (state: AuthenticationState, action) => {
			state.currentBusinessUnit = action.payload;
		},
		clearAuthenticationAction: () => initialState,
		clearCurrentBusinessUnitAction: (state: AuthenticationState) => {
			state.currentBusinessUnit = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(loginAction.pending, (state: AuthenticationState) => {
				state.loading = true;
			})
			.addCase(
				fetchUsersByRoleNameAction.pending,
				(state: AuthenticationState) => {
					state.loading = true;
				},
			)
			.addCase(logoutAction.pending, (state: AuthenticationState) => {
				state.loading = true;
			})
			.addCase(forgotPasswordAction.pending, (state: AuthenticationState) => {
				state.loading = true;
			})
			.addCase(resetPasswordAction.pending, (state: AuthenticationState) => {
				state.loading = true;
			})
			.addCase(
				accountActivationAction.pending,
				(state: AuthenticationState) => {
					state.loading = true;
				},
			)
			.addCase(
				resendActivationEmailAction.pending,
				(state: AuthenticationState) => {
					state.loading = true;
				},
			)
			.addCase(
				fetchBusinessUnitAction.pending,
				(state: AuthenticationState) => {
					state.loading = true;
				},
			)
			/** Fulfilled */
			.addCase(loginAction.fulfilled, (state: AuthenticationState, action) => {
				const {
					token,
					userId,
					businessUnits,
					availableLegTypes,
					availableStageTypes,
					userFirstName,
					userLastName,
					roles,
				} = action.payload;

				state.token = token;
				state.userId = userId;
				state.businessUnits = businessUnits;
				state.availableLegTypes = LEG_TYPES.filter((leg) =>
					availableLegTypes?.includes(leg.id),
				);
				state.availableStageTypes = LEG_TYPES.filter((leg) =>
					availableStageTypes?.includes(leg.id),
				);
				state.currentUser = {
					id: userId,
					firstName: userFirstName,
					lastName: userLastName,
					roles: roles,
				};

				const decodedToken = jwtDecode(action?.payload?.token);
				state.expiryTokenValue = decodedToken.exp;
				state.loading = false;
			})
			.addCase(
				fetchUsersByRoleNameAction.fulfilled,
				(
					state: AuthenticationState,
					action: PayloadAction<CelerumResponse>,
				) => {
					state.users = action.payload.data as IUser[];
					state.loading = false;
				},
			)
			.addCase(logoutAction.fulfilled, (state: AuthenticationState) => {
				state.token = undefined;
				state.userId = undefined;
				state.businessUnits = undefined;
				state.loading = false;
			})
			.addCase(forgotPasswordAction.fulfilled, (state: AuthenticationState) => {
				state.loading = false;
			})
			.addCase(resetPasswordAction.fulfilled, (state: AuthenticationState) => {
				state.loading = false;
			})
			.addCase(
				accountActivationAction.fulfilled,
				(state: AuthenticationState) => {
					state.loading = false;
				},
			)
			.addCase(
				resendActivationEmailAction.fulfilled,
				(state: AuthenticationState) => {
					state.loading = false;
					state.errorCode = 0;
				},
			)
			.addCase(
				fetchBusinessUnitAction.fulfilled,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.currentBusinessUnit = action.payload;
					state.availableLegTypes = LEG_TYPES.filter((leg) =>
						action.payload.availableLegTypes.includes(leg.id),
					);
					state.availableStageTypes = LEG_TYPES.filter((leg) =>
						action.payload.availableStageTypes.includes(leg.id),
					);
				},
			)
			/** Rejected */
			.addCase(loginAction.rejected, (state: AuthenticationState, action) => {
				state.loading = false;
				state.errorCode = generateErrorCode(action.error.code);
				state.notificationType = NotificationType.Error;
				state.message = generateErrorMessage(state.errorCode);
			})
			.addCase(
				fetchUsersByRoleNameAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.users = [];
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			)
			.addCase(logoutAction.rejected, (state: AuthenticationState, action) => {
				state.loading = false;
				state.errorCode = generateErrorCode(action.error.code);
				state.notificationType = NotificationType.Error;
				state.message = generateErrorMessage(state.errorCode);
			})
			.addCase(
				resetPasswordAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			)
			.addCase(
				forgotPasswordAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			)
			.addCase(
				accountActivationAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			)
			.addCase(
				resendActivationEmailAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			)
			.addCase(
				fetchBusinessUnitAction.rejected,
				(state: AuthenticationState, action) => {
					state.loading = false;
					state.errorCode = generateErrorCode(action.error.code);
					state.notificationType = NotificationType.Error;
					state.message = generateErrorMessage(state.errorCode);
				},
			);
	},
});

export const {
	selectBusinessUnitAction,
	resetAuthenticationNotificationAction,
	clearAuthenticationAction,
	clearCurrentBusinessUnitAction,
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
