import { useEffect, useMemo, useState } from "react";
import { LEG_STATUS_MAPPINGS } from "../../../../../../common/models/src/lib/constants/leg.constants";
import {
	ABSENT,
	NOT_AVAILABLE,
} from "../../../../../../common/models/src/lib/constants/messages.constants";
import {
	LegStatus,
	LegType,
} from "../../../../../../common/models/src/lib/enums/leg.enum";
import { ModalType } from "../../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IOperationLeg } from "../../../../../../common/models/src/lib/interfaces/leg.interface";
import type { AnyLeg } from "../../../../../../common/models/src/lib/types/leg.type";
import { useAppDispatchWithNotifications } from "../../../../../../common/stores/src/lib/utils";
import {
	CelerumButtonDeleteGrid,
	CelerumButtonEditGrid,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumInteractiveStatus } from "../../../../../../common/ui/src/lib/components/celerum-interactive-status/celerum-interactive-status.component";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import { getFormattedCurrency } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { renderLegTypeTitle } from "../../../../../../common/utils/src/lib/helpers/leg.helpers";
import {
	fetchJobByIdAction,
	fetchJobStatusAction,
} from "../../../../../../jobs/data-access/src/lib/jobs.slice";
import {
	fetchLoadByIdAction,
	fetchLoadStatusAction,
} from "../../../../../../loads/data-access/src/lib/loads.slice";
import {
	changeLegStatusAction,
	deleteLegAction,
} from "../../../../../data-access/src/lib/legs.slice";
import styles from "./leg-accordion-header.module.scss";

interface LegAccordionHeaderProps {
	parentType: ParentType;
	parentId: string | undefined;
	leg: AnyLeg;
	expanded: boolean;
	isJobInvoiced?: boolean;
	setSelectedLeg: (leg: AnyLeg) => void;
}

const DEFAULT_MAX_GOODS = 7;

export const LegAccordionHeader = ({
	parentId,
	leg,
	expanded,
	parentType,
	isJobInvoiced = false,
	setSelectedLeg,
}: LegAccordionHeaderProps) => {
	const {
		id,
		uniqueId,
		cost,
		currency,
		goods,
		type,
		status,
		availableStatuses,
	} = leg;

	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [maxGoods, setMaxGoods] = useState<number>(DEFAULT_MAX_GOODS);

	/** This code verifies if the leg has been assigned a business unit or rather a driver or subcontractor. */
	const isLegTransferred = useMemo(() => {
		const operationLeg = leg as IOperationLeg;
		return !!(
			operationLeg?.transferBusinessUnitId &&
			!(
				operationLeg?.driver ??
				operationLeg?.trailer ??
				operationLeg?.truck ??
				operationLeg?.subcontractor
			)
		);
	}, [leg]);

	const handleStatusChange = async (legId: number, status: number) => {
		if (parentId && !Number.isNaN(Number.parseInt(parentId))) {
			const actionResult = await dispatchWithNotifications({
				action: changeLegStatusAction,
				payload: { legId, status },
				successMessage: "Status successfully updated.",
				errorMessage: "Could not update status.",
			});
			if (changeLegStatusAction.fulfilled.match(actionResult)) {
				dispatchWithNotifications({
					action:
						parentType === ParentType.Job
							? fetchJobStatusAction
							: fetchLoadStatusAction,
					payload: Number(parentId),
					errorMessage:
						parentType === ParentType.Job
							? "Could not fetch job status."
							: "Could not fetch load status.",
				});
			}
		}
	};

	const handleDelete = async (legId: number) => {
		if (parentId && !Number.isNaN(Number.parseInt(parentId))) {
			try {
				const actionResult = await dispatchWithNotifications({
					action: deleteLegAction,
					payload: legId,
					successMessage: `${
						parentType === ParentType.Job ? "Leg" : "Stage"
					} successfully deleted.`,
					errorMessage: `Could not delete ${
						parentType === ParentType.Job ? "Leg" : "Stage"
					}.`,
				});

				if (deleteLegAction.fulfilled.match(actionResult)) {
					dispatchWithNotifications({
						action:
							parentType === ParentType.Job
								? fetchJobByIdAction
								: fetchLoadByIdAction,
						payload: Number(parentId),
						errorMessage: "Could not fetch job.",
					});
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const renderCost = () => {
		return (
			<div className={styles.cost}>
				<span className={styles.cost__value}>
					{getFormattedCurrency(cost, currency?.code)}
				</span>
			</div>
		);
	};

	const renderLocation = () => {
		switch (type) {
			case LegType.Collection:
			case LegType.Delivery:
			case LegType.Segment:
			case LegType.CollectDeliver:
			case LegType.ContainerPickUp:
			case LegType.ContainerDropOff:
			case LegType.ContainerGoodsCollection:
			case LegType.ContainerGoodsDelivery:
			case LegType.LoadAndLash:
			case LegType.Devan:
				return `${leg?.collectionLocation?.name ?? NOT_AVAILABLE} >> ${
					leg?.deliveryLocation?.name ?? NOT_AVAILABLE
				}`;
			case LegType.Storage:
				return leg.storageLocation;
			case LegType.ClearCustoms:
				return leg.clearanceLocation;
			case LegType.Ferry:
				return leg.ferryRoute;
			default:
				return NOT_AVAILABLE;
		}
	};

	useEffect(() => {
		goods;
		const determineDisplayedGoods = () => {
			if (window.innerWidth < 768) {
				setMaxGoods(1);
			} else if (window.innerWidth < 1920) {
				setMaxGoods(2);
			} else if (window.innerWidth >= 1920 && window.innerWidth < 2560) {
				setMaxGoods(4);
			} else {
				setMaxGoods(DEFAULT_MAX_GOODS);
			}
		};
		determineDisplayedGoods();
	}, [goods]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.section}>
					<div className={styles.information}>
						<div className={styles.title}>
							<span className={styles.type}>{renderLegTypeTitle(leg)}</span>
							{currency?.code && cost && renderCost()}
						</div>
						<span className={styles.location}>{renderLocation()}</span>
					</div>
					{parentType === ParentType.Job && (
						<div className={styles.goods} style={{ opacity: expanded ? 0 : 1 }}>
							{goods?.map((item: IBase, index: number) => {
								if (index < maxGoods) {
									return (
										<CelerumTag
											key={`${item.id}-goods-${index}`}
											name={item?.name ?? ABSENT}
										/>
									);
								}
								return;
							})}
							{goods?.length > maxGoods && (
								<span className={styles.goods__more}>...and more</span>
							)}
						</div>
					)}
				</div>
				<div className={styles.section}>
					<CelerumInteractiveStatus
						status={status}
						isInteractive={status !== LegStatus.PartOfALoad && !isJobInvoiced}
						availableStatuses={availableStatuses}
						statusMappings={LEG_STATUS_MAPPINGS}
						onChange={(status) => handleStatusChange(id, status)}
					/>
					{isLegTransferred ? (
						<div className={styles.actions}>
							<div className={styles.transferred}>
								Transferred to another business unit.
							</div>
							<CelerumButtonEditGrid
								title="Edit"
								onClick={(event) => {
									event.stopPropagation();
									setSelectedLeg(leg);
								}}
							/>
							<CelerumButtonDeleteGrid
								title="Delete"
								onClick={(event) => {
									event.stopPropagation();
									setShowModal(true);
								}}
							/>
						</div>
					) : (
						LegStatus.PartOfALoad !== status &&
						!isJobInvoiced && (
							<div className={styles.actions}>
								<CelerumButtonEditGrid
									title="Edit"
									onClick={(event) => {
										event.stopPropagation();
										setSelectedLeg(leg);
									}}
								/>
								<CelerumButtonDeleteGrid
									title="Delete"
									onClick={(event) => {
										event.stopPropagation();
										setShowModal(true);
									}}
								/>
							</div>
						)
					)}
				</div>
			</div>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The leg will be completely removed and cannot be recovered."
				entityName="leg"
				entityProperty="with ID"
				entityValue={uniqueId}
				isOpen={showModal}
				handleSubmit={() => {
					id && handleDelete(id);
					setShowModal(false);
				}}
				handleClose={() => setShowModal(false)}
			/>
		</>
	);
};
