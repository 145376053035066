import {
	type CompositeFilterDescriptor,
	type FilterDescriptor,
	type State,
	isCompositeFilterDescriptor,
	process,
} from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import {
	Grid,
	GridCell,
	GridColumn,
	GridColumnMenuCheckboxFilter,
	type GridColumnProps,
	type GridSelectionChangeEvent,
	GridToolbar,
} from "@progress/kendo-react-grid";
import {
	InputPrefix,
	InputSuffix,
	TextBox,
} from "@progress/kendo-react-inputs";
import { merge } from "lodash";
import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useSet } from "react-use";
import styled from "styled-components";
import { TypedDropDownButton } from "../TypedDropDownButton";
import {
	type ColumnField,
	type TypedGridColumnProps,
	type WithId,
	downloadExcelFile,
	filterData,
	useLS,
	useStableArray,
} from "../helpers";
import { toCell, useDialogConfirm } from "../helpersReact";

type SettingsItemRenderProps = {
	name: string;
	hidden?: boolean;
	action?: () => void;
};

const toFilterDescriptor = (
	xx: (FilterDescriptor | CompositeFilterDescriptor)[],
) => {
	const result: FilterDescriptor[] = [];
	for (const x of xx) {
		isCompositeFilterDescriptor(x)
			? result.push(...toFilterDescriptor(x.filters))
			: result.push(x);
	}
	return result;
};

const useColumns = <T extends object>(
	name: string,
	defaultColumns: TypedGridColumnProps<T>[],
) => {
	const [columns, setColumns] = useLS<TypedGridColumnProps<T>[]>(
		`${name}-Columns`,
		defaultColumns,
	);
	const mergeColumns = useCallback(
		(newColumns: GridColumnProps[]) => {
			for (const newColumn of newColumns) {
				const index = columns.findIndex((x) => x.field === newColumn.field);
				if (index >= 0) columns[index] = merge(columns[index], newColumn);
			}
			setColumns(columns);
		},
		[columns, setColumns],
	);
	const oldColumns = useMemo(
		() => columns.map((x) => x.field).join(),
		[columns],
	);
	const newColumns = useMemo(
		() => defaultColumns.map((x) => x.field).join(),
		[defaultColumns],
	);
	const updateNewColumns = useCallback(
		() => setColumns(defaultColumns),
		[defaultColumns, setColumns],
	);
	useEffect(
		() => (oldColumns !== newColumns ? void updateNewColumns() : undefined),
		[oldColumns, newColumns, updateNewColumns],
	);
	return [columns, setColumns, mergeColumns] as const;
};
const SettingsItemRender = ({
	name,
	hidden,
	action,
}: SettingsItemRenderProps) => (
	<>
		{action ? <Icon name="gear" /> : <Icon name={hidden ? "x" : "check"} />}
		<span>{name}</span>
	</>
);
type GenericSettingsProps<T extends object> = {
	columns: TypedGridColumnProps<T>[];
	setColumns: Dispatch<SetStateAction<TypedGridColumnProps<T>[]>>;
	resetColumns: () => void;
};
const GenericSettings = <T extends object>({
	columns,
	setColumns,
	resetColumns,
}: GenericSettingsProps<T>) => {
	const columnsWithReset: SettingsItemRenderProps[] = [
		{ name: "Reset", action: resetColumns },
		...columns
			.filter((x) => x.field !== "actions")
			.map((x) => ({ name: x.title ?? "", hidden: x.hidden })),
	];
	const toggleColumn = useCallback(
		({ name, action }: SettingsItemRenderProps) => {
			if (action) return action();
			setColumns(
				columns.map((x) =>
					x.title === name ? { ...x, hidden: !x.hidden } : x,
				),
			);
		},
		[columns, setColumns],
	);
	return (
		<TypedDropDownButton
			icon="gear"
			text="Columns"
			items={columnsWithReset}
			itemRender={(x) => <SettingsItemRender {...x.item} />}
			onItemClick={(x) => toggleColumn(x.item)}
			popupSettings={{ animate: false }}
		/>
	);
};

type GenericSearchProps = {
	setSearch: Dispatch<SetStateAction<string>>;
	search: string;
	searchPlaceholder?: string;
};
const GenericSearch = ({
	setSearch,
	search,
	searchPlaceholder = "Search...",
}: GenericSearchProps) => (
	<TextBox
		className="grid-search"
		prefix={() => (
			<InputPrefix>
				<Icon name="search" />
			</InputPrefix>
		)}
		suffix={() => (
			<InputSuffix>
				<Icon
					name="x"
					style={{ cursor: "pointer" }}
					onClick={() => setSearch("")}
				/>
			</InputSuffix>
		)}
		onChange={(x) => setSearch(x.value?.toString() ?? "")}
		name="search"
		placeholder={searchPlaceholder}
		value={search}
	/>
);

const StyledGrid = styled(Grid)`
	tr.k-table-row,
	td.k-table-td {
		white-space: nowrap;
	}
	> .k-toolbar.k-grid-toolbar {
		padding-left: 0;
		display: flex;
		justify-content: flex-end;
	}
	.grid-search {
		min-width: 300px;
	}
	.grid-filtered-column {
		.k-grid-header-menu {
			color: red;
		}
	}
	.k-grid-content.k-virtual-content {
		scrollbar-color: unset;
	}
	display: grid;
	grid-template-rows: auto 1fr auto;
`;
const useSelection = <T extends WithId>(
	name: string,
	data: T[],
	onSelectionChange?: (ids: Set<T["id"]>) => void,
) => {
	const [savedSelectedIds, setSavedSelectedIds] = useLS<T["id"][]>(
		`${name}-Selection`,
		[],
	);
	const [
		selectedIds,
		{
			has: hasSelectedId,
			toggle: toggleSelectedId,
			clear: clearSelectedIds,
			add: addSelectedId,
		},
	] = useSet(new Set(savedSelectedIds));
	const dataWithSelection = useMemo(
		() => data.map((x) => ({ ...x, selected: hasSelectedId(x.id) })),
		[data, hasSelectedId],
	);
	useEffect(
		() => void setSavedSelectedIds([...selectedIds]),
		[selectedIds, setSavedSelectedIds],
	);
	useEffect(
		() => void onSelectionChange?.(selectedIds),
		[selectedIds, onSelectionChange],
	);
	const handleSelectionChange = useCallback(
		({ dataItem }: GridSelectionChangeEvent) =>
			dataItem && toggleSelectedId((dataItem as T).id),
		[toggleSelectedId],
	);
	return [
		selectedIds,
		dataWithSelection,
		handleSelectionChange,
		clearSelectedIds,
		addSelectedId,
	] as const;
};
const initialDataState: State = { take: 21, skip: 0 };
type GenericGridProps<T extends WithId> = {
	data: T[];
	name: string;
	defaultColumns: TypedGridColumnProps<T>[];
	refresh: () => void;
	extraButtons?: ReactNode;
	extraFilters?: ReactNode;
	loading: boolean;
	extraColumns?: TypedGridColumnProps<T>[];
	onSelectionChange?: (ids: Set<T["id"]>) => void;
	footer?: { [K in ColumnField<T>]?: (data: T[]) => ReactNode };
	callbackSearch?: (search: string) => void;
	searchPlaceholder?: string;
};
export const GenericDataGrid = <T extends WithId>({
	data,
	name,
	defaultColumns,
	extraColumns,
	extraFilters,
	refresh,
	extraButtons,
	loading,
	onSelectionChange,
	footer,
	callbackSearch,
	searchPlaceholder,
}: GenericGridProps<T>) => {
	const [
		selectedIds,
		dataWithSelection,
		handleSelectionChange,
		clearSelectedIds,
		addSelectedId,
	] = useSelection(name, data, onSelectionChange);
	const [dataState, setDataState] = useLS(
		`${name}-DataState`,
		initialDataState,
	);
	const mergedDefaultColumns = useStableArray([
		...defaultColumns,
		...(extraColumns ?? []),
	]);
	const [columns, setColumns, mergeColumns] = useColumns(
		name,
		mergedDefaultColumns,
	);
	const [search, setSearch] = useLS(`${name}-Search`, "");
	useEffect(() => void callbackSearch?.(search), [callbackSearch, search]);
	const [counter, setCounter] = useState(0);
	const resetColumns = useCallback(() => {
		setColumns(mergedDefaultColumns);
		setCounter(counter + 1);
	}, [counter, mergedDefaultColumns, setColumns]);
	const [toggleConfirmResetDialog, confirmResetDialog] = useDialogConfirm(
		"Are you sure you want to reset the columns?",
		"Reset Columns",
		resetColumns,
	);
	const filteredData = useMemo(
		() => filterData(dataWithSelection, search),
		[dataWithSelection, search],
	);
	const dataResult = useMemo(
		() => process(filteredData, dataState),
		[filteredData, dataState],
	);
	const allDataResult = useMemo(
		() => process(filteredData, { ...dataState, skip: 0, take: data.length }),
		[filteredData, dataState, data.length],
	);

	const filters = useMemo(
		() => toFilterDescriptor(dataState.filter?.filters ?? []),
		[dataState.filter?.filters],
	);
	const hasFilters = !!filters.length || !!search || !!dataState.sort?.length;
	const resetFilters = () => {
		setDataState(initialDataState);
		setSearch("");
	};
	const defaultColumnsByField = useMemo(
		() =>
			mergedDefaultColumns.reduce(
				(acc, x) => Object.assign(acc, { [x.field]: x }),
				{} as Record<ColumnField<T>, TypedGridColumnProps<T>>,
			),
		[mergedDefaultColumns],
	);
	const columnsWithoutActions = useMemo(
		() => columns.filter((x) => x.field !== "actions"),
		[columns],
	);
	const exportExcel = useCallback(
		() => downloadExcelFile(name, allDataResult.data, columnsWithoutActions),
		[columnsWithoutActions, allDataResult.data, name],
	);
	const isColumnFiltered = useCallback(
		(field: ColumnField<T>) => {
			const isActive = (
				x: FilterDescriptor | CompositeFilterDescriptor,
			): boolean => {
				if ("filters" in x) {
					return x.filters.some((y) => isActive(y));
				}
				return x.field === field;
			};
			return dataState.filter?.filters?.some(isActive);
		},
		[dataState.filter],
	);
	useEffect(
		() =>
			void (
				search.length && setDataState((x) => ({ ...x, ...initialDataState }))
			),
		[search.length, setDataState],
	);
	return (
		<>
			{confirmResetDialog}
			<StyledGrid
				key={counter}
				columnMenu={(x) => (
					<GridColumnMenuCheckboxFilter
						{...x}
						data={dataWithSelection}
						expanded
					/>
				)}
				onDataStateChange={(x) =>
					setDataState({ ...dataState, ...x.dataState })
				}
				onColumnReorder={(x) => mergeColumns(x.columns)}
				onColumnResize={(x) => mergeColumns(x.columns)}
				dataItemKey="id"
				selectedField="selected"
				selectable={{
					enabled: true,
					drag: false,
					cell: false,
					mode: "multiple",
				}}
				onSelectionChange={handleSelectionChange}
				onHeaderSelectionChange={() => {
					clearSelectedIds();
					if (!selectedIds.size) {
						const allIds = new Set<T["id"]>(
							allDataResult.data.map((x) => x.id),
						);
						allIds.forEach(addSelectedId);
					}
				}}
				data={dataResult}
				size="small"
				resizable
				pageable
				sortable
				reorderable
				{...dataState}
			>
				<GridToolbar>
					{extraFilters}
					<GenericSettings
						columns={columns}
						setColumns={setColumns}
						resetColumns={toggleConfirmResetDialog}
					/>
					<Button
						icon="refresh"
						onClick={refresh}
						title="Refresh"
						disabled={loading}
						style={{ color: loading ? "green" : undefined }}
					/>
					<Button
						icon="filter"
						onClick={resetFilters}
						title="Reset Filters"
						disabled={!hasFilters}
						style={{ color: hasFilters ? "red" : undefined }}
					/>
					<Button icon="excel" onClick={exportExcel} title="Export to Excel" />
					<GenericSearch
						setSearch={setSearch}
						search={search}
						searchPlaceholder={searchPlaceholder}
					/>
					{extraButtons}
				</GridToolbar>
				{onSelectionChange && (
					<GridColumn
						field="selected"
						width="50px"
						resizable={false}
						headerSelectionValue={!!selectedIds.size}
						// biome-ignore lint/style/noNonNullAssertion: forcing null makes the column menu empty
						columnMenu={null!}
					/>
				)}
				{columns.map((x) => {
					if (x.hidden) return null;
					const defaultColumn = defaultColumnsByField[x.field];
					if (!defaultColumn) return null;
					const footerCell = footer?.[x.field];
					const actionModifier = {};
					if (x.field === "actions") {
						Object.assign(actionModifier, {
							resizable: false,
							reorderable: false,
							sortable: false,
						});
					}
					return (
						<GridColumn
							key={x.field}
							{...defaultColumn}
							{...x}
							headerClassName={
								isColumnFiltered(x.field) ? "grid-filtered-column" : undefined
							}
							columnMenu={
								x.field === "actions" ||
								x.field.endsWith("Date") ||
								x.field.endsWith("Time")
									? // biome-ignore lint/style/noNonNullAssertion: forcing null makes the column menu empty
										null!
									: undefined
							}
							cell={
								defaultColumn.cell ??
								((x) => (
									<GridCell
										{...x}
										render={() => toCell(x.dataItem[x.field as keyof T])}
									/>
								))
							}
							{...actionModifier}
							footerCell={
								footerCell ? () => footerCell(allDataResult.data) : undefined
							}
						/>
					);
				})}
			</StyledGrid>
		</>
	);
};
