import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DRIVERS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/driver.constants";
import { DRIVER_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { CommandCellType } from "../../../../../common/models/src/lib/enums/command-cell.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IDateFilter } from "../../../../../common/models/src/lib/interfaces/filter.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import {
	clearDriversAction,
	fetchDriversAction,
} from "../../../../data-access/src/lib/drivers.slice";
import { DriverForm } from "../components/driver-form/driver-form.component";
import { canDelete } from "../helpers/driver-option.helpers";
import { useDriverActionSelected } from "../hooks/use-driver-action-selected.hook";

interface DriversFilterState {
	licenceExpirationDateFilter: IDateFilter[];
}

const initialFilterState: DriversFilterState = {
	licenceExpirationDateFilter: [],
};

export const DriversFeature = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const handleSelectedAction = useDriverActionSelected();

	const { data, total, loading } = useAppSelector((state) => state.drivers);

	const [showModal, setShowModal] = useState<{
		create: boolean;
		actions: boolean;
	}>({
		create: false,
		actions: false,
	});
	const [selectedDriverId, setSelectedDriverId] = useState<number | null>(null);
	const [selectedDriverName, setSelectedDriverName] = useState<
		string | undefined
	>(undefined);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: DRIVERS_PAGE_SIZE });
	const [filters, setFilters] = useState<string>("");
	const [searchFilter, setSearchFilter] = useState<string>("");

	const [driverFilters, setDriverFilters] =
		useOldLocalStorage<DriversFilterState>(
			"driversFilterState",
			initialFilterState,
		);

	const renderedDrivers = useMemo(
		() =>
			data.map((driver) => ({
				...driver,
				licenceExpirationDate: new Date(driver.licenceExpirationDate),
				primaryPhone: driver.phones?.length ? driver.phones[0] : "",
				primaryEmail: driver.emails?.length ? driver.emails[0] : "",
			})),
		[data],
	);

	const handleMoreOptions = {
		canDelete,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		const selectedDriver = data.find((driver) => driver.id === id);

		setSelectedDriverId(id);
		setSelectedDriverName(selectedDriver?.fullName || undefined);

		setSelectedAction(CelerumActions[value]);
		setShowModal({ ...showModal, actions: true });
	};

	const clearFilters = () => {
		setDriverFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading.grid) {
					return;
				}

				const page = Math.ceil(skip / DRIVERS_PAGE_SIZE) + 1;
				dispatch(
					fetchDriversAction({
						page,
						pageSize: DRIVERS_PAGE_SIZE,
						sort,
						filters,
					}),
				);
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearDriversAction());
		dispatch(
			fetchDriversAction({
				page: 1,
				pageSize: DRIVERS_PAGE_SIZE,
				sort,
				filters,
			}),
		);
	};

	const openCreateModal = () => {
		setShowModal({ ...showModal, create: true });
	};

	const closeModal = () => {
		setShowModal({ create: false, actions: false });
		setSelectedDriverId(null);
	};

	useEffect(() => {
		const filters = buildFilterQueryString(
			searchFilter,
			["fullName", "notes", "truck.name"],
			driverFilters.licenceExpirationDateFilter,
		);

		setFilters(filters);

		if (searchFilter || driverFilters.licenceExpirationDateFilter.length) {
			dispatchWithNotifications({
				action: fetchDriversAction,
				payload: { page: 1, pageSize: DRIVERS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch drivers.",
			});
		} else {
			dispatchWithNotifications({
				action: fetchDriversAction,
				payload: { page: 1, pageSize: DRIVERS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch drivers.",
			});
		}

		return () => {
			dispatch(clearDriversAction());
		};
	}, [searchFilter, driverFilters, dispatch, dispatchWithNotifications]);

	useEffect(() => {
		if (loading.grid) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.grid]);

	return (
		<>
			<CelerumGridHeader
				title="Drivers"
				numberOfItems={total}
				addButtonName="Add Driver"
				addButtonDisabled={loading.grid}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setFilters={setDriverFilters}
					setSearchFilter={setSearchFilter}
					filters={{
						licenceExpirationDateFilter: {
							name: "Licence Expiration Date",
							column: "licenceExpirationDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					initialValues={{
						licenceExpirationDateFilter:
							driverFilters.licenceExpirationDateFilter,
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				columns={DRIVER_GRID_COLUMNS}
				page={page}
				setPage={setPage}
				data={renderedDrivers}
				total={total}
				loading={loading.grid}
				commandCellType={CommandCellType.NavigateViewMore}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleNavigate={(id) =>
					navigate(`${id}`, { state: { from: window.location.pathname } })
				}
				handleMoreOptions={handleMoreOptions}
				handleActionSelected={handleMoreOptionsSelected}
			/>
			<CelerumModal
				title={"Add New Driver"}
				width={ModalSize.Large}
				toggleDialog={closeModal}
				visible={showModal.create}
			>
				<DriverForm onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this driver?`
				}
				entityName="driver"
				entityProperty="named"
				entityValue={selectedDriverName}
				isOpen={showModal.actions}
				subtitle={"The driver cannot be recovered."}
				type={ModalType.Delete}
				handleSubmit={() => {
					handleSelectedAction(
						selectedAction,
						selectedDriverId,
						selectedDriverName,
					);
					closeModal();
				}}
				handleClose={closeModal}
			/>
		</>
	);
};
