import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { concat } from "lodash";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LEG_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../../common/models/src/lib/constants/grid.constants";
import { SUPPLIER_INVOICES } from "../../../../../common/models/src/lib/constants/invoice.constants";
import { JOBS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/job.constants";
import {
	LEGS_PAGE_SIZE,
	LEG_STATUSES,
	LEG_STATUS_MAPPINGS,
} from "../../../../../common/models/src/lib/constants/leg.constants";
import { ABSENT } from "../../../../../common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import { LegStatus } from "../../../../../common/models/src/lib/enums/leg.enum";
import { ModalType } from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { ILegSummary } from "../../../../../common/models/src/lib/interfaces/leg.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { renderLegTypeTitle } from "../../../../../common/utils/src/lib/helpers/leg.helpers";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchJobTypesAction } from "../../../../../jobs/data-access/src/lib/job-types.slice";
import {
	changeLegStatusAction,
	clearLegsAction,
	deleteLegAction,
	fetchLegSummaryAction,
} from "../../../../data-access/src/lib/legs.slice";
import {
	canCancel,
	canDelete,
	canDuplicate,
	canPause,
	canResume,
} from "../helpers/leg-option.helpers";

interface LegFiltersState {
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	jobTypeFilter: IFilterItem[];
	legTypeFilter: IFilterItem[];
	collectionDateFilter: IDateFilter[];
	deliveryDateFilter: IDateFilter[];
	supplierInvoiceFilter: IFilterItem[];
}

const initialFilterState: LegFiltersState = {
	statusFilter: [],
	assignedToFilter: [],
	jobTypeFilter: [],
	legTypeFilter: [],
	collectionDateFilter: [],
	deliveryDateFilter: [],
	supplierInvoiceFilter: [],
};

export const LegsFeature = () => {
	const navigate = useNavigate();

	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const {
		jobTypes,
		authentication: { users, availableLegTypes },
		legs: { summary, total, loading },
	} = useAppSelector((state) => ({
		jobTypes: state.jobTypes.data,
		authentication: state.authentication,
		legs: state.legs,
	}));

	const [legFilters, setLegFilters] = useOldLocalStorage<LegFiltersState>(
		"legFilterState",
		initialFilterState,
	);

	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: LEGS_PAGE_SIZE });
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedLegId, setSelectedLegId] = useState<number | null>(null);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [showActionModal, setShowActionModal] = useState<boolean>(false);

	const renderedLegSummary = useMemo(() => {
		return summary.map((leg: ILegSummary) => {
			const collectionDate =
				leg.collectionDate && new Date(leg.collectionDate).toLocaleString();
			const deliveryDate =
				leg.deliveryDate && new Date(leg.deliveryDate).toLocaleString();

			return {
				...leg,
				collectionDate,
				deliveryDate,
				parentId: leg.jobId || leg.loadId,
				legId: leg.id,
				type: renderLegTypeTitle(leg),
				supplierInvoice:
					leg.supplierInvoice !== undefined
						? SUPPLIER_INVOICES[leg.supplierInvoice]?.name
						: ABSENT,
			};
		});
	}, [summary]);

	const renderedJobTypesList = useMemo(
		() =>
			jobTypes.map((jobType) => {
				return {
					id: jobType.id,
					name: jobType.name,
				};
			}),
		[jobTypes],
	) as IBase[];

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const handleMoreOptions = {
		canDelete,
		canCancel,
		canPause,
		canDuplicate,
		canResume,
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < summary.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (summary[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / LEGS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchLegSummaryAction,
					payload: { page, pageSize: LEGS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch legs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearLegsAction());
		dispatchWithNotifications({
			action: fetchLegSummaryAction,
			payload: { page: 1, pageSize: LEGS_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch legs.",
		});
	};

	const closeModal = () => {
		setShowActionModal(false);
		setSelectedLegId(null);
		setSelectedAction(null);
	};

	const handleStatusChange = (legId: number, status: number) => {
		dispatchWithNotifications({
			action: changeLegStatusAction,
			payload: { legId, status },
			successMessage: "Status successfully updated.",
			errorMessage: "Could not update status.",
		});
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		setSelectedLegId(id);

		setSelectedAction(CelerumActions[value]);
		setShowActionModal(true);
	};

	const clearFilters = () => {
		setLegFilters(initialFilterState);
	};

	useEffect(() => {
		const typesFilterLists = [];

		legFilters.statusFilter?.length &&
			typesFilterLists.push(legFilters.statusFilter);
		legFilters.jobTypeFilter?.length &&
			typesFilterLists.push(legFilters.jobTypeFilter);
		legFilters.legTypeFilter?.length &&
			typesFilterLists.push(legFilters.legTypeFilter);
		legFilters.assignedToFilter?.length &&
			typesFilterLists.push(legFilters.assignedToFilter);
		legFilters.supplierInvoiceFilter?.length &&
			typesFilterLists.push(legFilters.supplierInvoiceFilter);

		const combinedDateFilters = concat(
			legFilters.collectionDateFilter,
			legFilters.deliveryDateFilter,
		);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"uniqueId",
				"customerName",
				"driverName",
				"underwayLegName",
				"collectionLocationName",
				"deliveryLocationName",
				"assignedToName",
			],
			combinedDateFilters,
			typesFilterLists,
		);
		setFilters(filters);

		if (searchFilter || combinedDateFilters.length || typesFilterLists.length) {
			dispatch(
				fetchLegSummaryAction({ page: 1, pageSize: JOBS_PAGE_SIZE, filters }),
			);
		} else {
			dispatch(fetchLegSummaryAction({ page: 1, pageSize: JOBS_PAGE_SIZE }));
		}
		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [searchFilter, legFilters, dispatch]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatch(fetchJobTypesAction());

		return () => {
			dispatch(clearLegsAction());
		};
	}, [dispatch]);

	return (
		<>
			<CelerumGridHeader title="Legs" numberOfItems={total}>
				<CelerumFilters
					filters={{
						statusFilter: {
							name: "Status",
							column: "status",
							values: LEG_STATUSES,
							type: FilterItemType.DROPDOWN,
						},
						jobTypeFilter: {
							name: "Job Type",
							column: "jobTypeId",
							values: renderedJobTypesList,
							type: FilterItemType.DROPDOWN,
						},
						legTypeFilter: {
							name: "Leg Type",
							column: "type",
							values: availableLegTypes,
							type: FilterItemType.DROPDOWN,
						},
						supplierInvoiceFilter: {
							name: "Supplier Invoice",
							column: "supplierInvoice",
							values: SUPPLIER_INVOICES,
							type: FilterItemType.DROPDOWN,
						},
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						collectionDateFilter: {
							name: "Collection Date",
							column: "collectionDate",
							type: FilterItemType.DATERANGE,
						},
						deliveryDateFilter: {
							name: "Delivery Date",
							column: "deliveryDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					setFilters={setLegFilters}
					initialValues={{
						statusFilter: legFilters.statusFilter,
						jobTypeFilter: legFilters.jobTypeFilter,
						legTypeFilter: legFilters.legTypeFilter,
						assignedToFilter: legFilters.assignedToFilter,
						collectionDateFilter: legFilters.collectionDateFilter,
						deliveryDateFilter: legFilters.deliveryDateFilter,
						supplierInvoiceFilter: legFilters.supplierInvoiceFilter,
					}}
					setSearchFilter={setSearchFilter}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				data={renderedLegSummary}
				commandCellType={1}
				total={total}
				page={page}
				setPage={setPage}
				columns={LEG_GRID_COLUMNS}
				handleNavigate={(id) => {
					const itemWithParentId = renderedLegSummary.find(
						(leg) => leg.jobId === id || leg.loadId === id,
					);
					if (itemWithParentId?.jobId === id) {
						navigate(`/jobs/${id}`, {
							state: { from: window.location.pathname },
						});
					} else {
						navigate(`/loads/${id}`, {
							state: { from: window.location.pathname },
						});
					}
				}}
				handleMoreOptions={handleMoreOptions}
				handleStatusChange={handleStatusChange}
				handleActionSelected={handleMoreOptionsSelected}
				navigateItem="parentId"
				actionField="legId"
				loading={loading}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				statusMappings={LEG_STATUS_MAPPINGS}
			/>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this leg?`
				}
				entityName="leg"
				entityProperty="with ID"
				entityValue={selectedLegId?.toString()}
				isOpen={showActionModal}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The leg cannot be recovered."
						: "This action can be reverted afterwards."
				}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={() => {
					switch (selectedAction) {
						case CelerumActions.Delete: {
							dispatchWithNotifications({
								action: deleteLegAction,
								payload: selectedLegId,
								successMessage: `Leg with id ${selectedLegId} successfully deleted.`,
								errorMessage: "Could not delete leg.",
							});
							break;
						}
						case CelerumActions.Cancel: {
							dispatchWithNotifications({
								action: changeLegStatusAction,
								payload: { legId: selectedLegId, status: LegStatus.Cancelled },
								successMessage: `Leg with id ${selectedLegId} successfully cancelled.`,
								errorMessage: "Could not cancel leg.",
							});
							break;
						}
						case CelerumActions.Resume: {
							dispatchWithNotifications({
								action: changeLegStatusAction,
								payload: { legId: selectedLegId, status: LegStatus.Underway },
								successMessage: `Leg with id ${selectedLegId} resumed.`,
								errorMessage: "Could not resume leg.",
							});
							break;
						}
					}
					closeModal();
				}}
				handleClose={closeModal}
			/>
		</>
	);
};
