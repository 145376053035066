import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type {
	ILoadDetails,
	ILoadRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/load.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormTextArea,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import { updateLoadAction } from "../../../../../data-access/src/lib/loads.slice";
import styles from "./edit-load-information-form.module.scss";

interface EditLoadInformationFormProps {
	formState: ILoadDetails;
	onClose: () => void;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
}

export const EditLoadInformationForm = ({
	formState,
	onClose,
	setModalSize,
}: EditLoadInformationFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const handleInputChange = useInputChange();

	const { users, loadTypes, locations, trailers } = useAppSelector((state) => ({
		users: state.authentication.users,
		locations: state.locations.data,
		trailers: state.trailers.data,
		loadTypes: state.loadTypes.data,
	}));

	const [loadTypeId, setLoadTypeId] = useState<number>(formState.loadType?.id);
	const [trailerId, setTrailerId] = useState<number>(formState.trailer?.id);
	const [assignedToId, setAssignedToId] = useState<string | number>(
		formState.assignedUser?.id,
	);
	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [formData, setFormData] = useState<ILoadDetails>();
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		const { startDate, endDate, notes } = event.values;

		if (!event.isValid) {
			return;
		}

		const requestObject: ILoadRequestDto = {
			id: formState.id,
			loadTypeId,
			trailerId,
			startLocationId: formData?.startLocation?.id || undefined,
			startDate: startDate && toISOStringWithoutTimezoneOffset(startDate),
			endLocationId: formData?.endLocation?.id || undefined,
			endDate: endDate && toISOStringWithoutTimezoneOffset(endDate),
			assignedTo: assignedToId,
			notes,
		};

		const actionResult = await dispatchWithNotifications({
			action: updateLoadAction,
			payload: requestObject,
			successMessage: `Load with ID ${formState.uniqueId} was successfully updated.`,
			errorMessage: `Could not update load with ID ${formState.uniqueId}.`,
		});

		updateLoadAction.fulfilled.match(actionResult) && onClose();
	};

	useEffect(() => {
		if (formState) {
			setFormData(formState);
		}
	}, [formState]);

	useEffect(() => {
		showLocationForm
			? setModalSize(ModalSize.Medium)
			: setModalSize(ModalSize.Large);
	}, [setModalSize, showLocationForm]);

	const renderLoadForm = () => (
		<Form
			initialValues={formData || formState}
			onSubmitClick={handleSubmit}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<Field
									id="loadType"
									dataItemKey="id"
									label="Load Type"
									textField="name"
									name="loadType"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										handleInputChange(event, "loadType", setFormData);
										setLoadTypeId(event.target.value.id);
									}}
									data={loadTypes}
									validator={requiredValidator}
									required
								/>
								<Field
									id="startLocation"
									dataItemKey="id"
									label="Start Location"
									textField="nameAndAddress"
									name="startLocation"
									component={CelerumSuggestionsInput}
									handleSearchChange={handleSearchLocationsChange}
									data={locations}
									onChange={(event) => {
										handleInputChange(event, "startLocation", setFormData);
									}}
									validator={requiredValidator}
									required
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("startLocation");
									}}
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="startDate"
									name="startDate"
									label="Start Date"
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									onChange={(event) =>
										handleInputChange(event, "startDate", setFormData)
									}
									required
								/>
								<Field
									id="endLocation"
									dataItemKey="id"
									label="End Location"
									textField="nameAndAddress"
									name="endLocation"
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									component={CelerumSuggestionsInput}
									onChange={(event) => {
										handleInputChange(event, "endLocation", setFormData);
									}}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("endLocation");
									}}
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="endDate"
									name="endDate"
									label="End Date"
									component={CelerumFormDateTimePicker}
									onChange={(event) =>
										handleInputChange(event, "endDate", setFormData)
									}
								/>
							</div>
							<div>
								<Field
									id="trailer"
									dataItemKey="id"
									label="Trailer"
									textField="name"
									name="trailer"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										setTrailerId(event.target.value.id);
										handleInputChange(event, "trailer", setFormData);
									}}
									data={trailers}
								/>
								<Field
									id="assignedUser"
									dataItemKey="id"
									label="Assigned To"
									textField="fullName"
									name="assignedUser"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										setAssignedToId(event.target.value.id);
										handleInputChange(event, "assignedUser", setFormData);
									}}
									data={users}
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									type="text"
									onChange={(event) => {
										handleInputChange(event, "notes", setFormData);
									}}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!isFormModified && !formRenderProps.allowSubmit}
						title="Save Changes"
					/>
				</FormElement>
			)}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<ILoadDetails>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderLoadForm() : renderLocationForm();
};
