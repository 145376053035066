import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	INVOICES_FEATURE_KEY,
	NEW_INVOICE_STATE,
} from "../../../../common/models/src/lib/constants/invoice.constants";
import { JobStatus } from "../../../../common/models/src/lib/enums/job.enum";
import type { TemplateType } from "../../../../common/models/src/lib/enums/template.enum";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	GenerateInvoiceDto,
	GeneratedInvoiceResponse,
	ICheckInvoiceRequestDto,
	ICheckInvoiceRequestDtos,
	IChecklist,
	IChecklistItem,
	IChecklistItemUpdateRequestDto,
	IInvoiceableJob,
	IMarkInvoiceAsSentRequestDto,
	ITemplate,
	InvoicesState,
} from "../../../../common/models/src/lib/interfaces/invoice.interface";
import {
	changeInvoiceStatus,
	createInvoice,
	fetchAllChecklists,
	fetchAllTemplates,
	fetchInvoiceChecklist,
	fetchInvoiceableJobs,
	getPreview,
	markInvoiceableJobAsChecked,
	markInvoiceableJobAsSent,
	markInvoiceableJobsAsChecked,
	reInvoiceJob,
	sendInvoiceableJobToReview,
	updateInvoiceChecklistState,
} from "./invoices-data-access";

const initialState: InvoicesState = {
	data: [],
	checklistItems: [],
	checklists: [],
	templates: [],
	generatedInvoices: [],
	total: 0,
	aggregateResults: null,
	loading: { invoices: false, checklist: false, areInvoicesGenerated: false },
	errors: null,
};

export const fetchInvoiceableJobsAction = createAsyncThunk(
	"invoices/fetchInvoiceableJobs",
	async (params: CelerumQueryParams) => {
		return fetchInvoiceableJobs(params);
	},
);

export const fetchInvoiceChecklistAction = createAsyncThunk(
	"invoices/fetchInvoiceChecklistAction",
	async (id: number) => {
		return fetchInvoiceChecklist(id);
	},
);

export const updateInvoiceChecklistStateAction = createAsyncThunk(
	"invoices/updateInvoiceChecklistStateAction",
	async (data: IChecklistItemUpdateRequestDto) => {
		const { id, checklist } = data;
		return updateInvoiceChecklistState(id, checklist);
	},
);

export const markInvoiceableJobAsCheckedAction = createAsyncThunk(
	"invoices/markInvoiceableJobAsChecked",
	(data: ICheckInvoiceRequestDto) => {
		return markInvoiceableJobAsChecked(data);
	},
);

export const markInvoiceableJobsAsCheckedAction = createAsyncThunk(
	"invoices/markInvoiceableJobsAsChecked",
	(data: ICheckInvoiceRequestDtos) => {
		return markInvoiceableJobsAsChecked(data);
	},
);
type changeInvoiceStatusActionReturnType = {
	parameters: ICheckInvoiceRequestDto;
	response: number;
};
export const changeInvoiceStatusAction = createAsyncThunk(
	"invoices/changeInvoiceStatus",
	async (data: ICheckInvoiceRequestDto) => {
		return { parameters: data, response: await changeInvoiceStatus(data) };
	},
);

export const markInvoiceableJobAsSentAction = createAsyncThunk(
	"invoices/markInvoiceableJobAsSent",
	(data: IMarkInvoiceAsSentRequestDto) => {
		return markInvoiceableJobAsSent(data);
	},
);

export const sendInvoiceableJobToReviewAction = createAsyncThunk(
	"invoices/sendInvoiceableJobToReview",
	(id: number) => {
		return sendInvoiceableJobToReview(id);
	},
);

export const reInvoiceJobAction = createAsyncThunk(
	"invoices/reInvoiceJob",
	(id: number) => {
		return reInvoiceJob(id);
	},
);

export const getPreviewAction = createAsyncThunk(
	"invoices/getPreview",
	(id: number) => {
		return getPreview(id);
	},
);

export const fetchAllChecklistsAction = createAsyncThunk(
	"invoices/fetchAllChecklistsAction",
	async () => {
		return fetchAllChecklists();
	},
);

export const fetchAllTemplatesByTypeAction = createAsyncThunk(
	"invoices/fetchAllTemplatesByTypeAction",
	async (type: TemplateType) => {
		return fetchAllTemplates(type);
	},
);

export const createInvoiceAction = createAsyncThunk(
	"invoices/createInvoice",
	async (dto: GenerateInvoiceDto) => {
		return createInvoice(dto);
	},
);

const invoicesSlice = createSlice({
	name: INVOICES_FEATURE_KEY,
	initialState,
	reducers: {
		clearInvoiceableJobsAction: (state: InvoicesState) => {
			state.data = initialState.data;
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchInvoiceableJobsAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(fetchInvoiceChecklistAction.pending, (state) => {
				state.loading.checklist = true;
			})
			.addCase(updateInvoiceChecklistStateAction.pending, (state) => {
				state.loading.checklist = false;
			})
			.addCase(markInvoiceableJobAsCheckedAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(markInvoiceableJobsAsCheckedAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(markInvoiceableJobAsSentAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(sendInvoiceableJobToReviewAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(reInvoiceJobAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(getPreviewAction.pending, (state) => {
				state.loading.invoices = true;
			})
			.addCase(fetchAllChecklistsAction.pending, (state) => {
				state.loading.checklist = true;
			})
			.addCase(fetchAllTemplatesByTypeAction.pending, (state) => {
				state.loading.checklist = true;
			})
			.addCase(createInvoiceAction.pending, (state) => {
				state.loading.areInvoicesGenerated = true;
			})
			.addCase(changeInvoiceStatusAction.pending, (state) => {
				state.loading.invoices = true;
			})
			/** Fulfilled */
			.addCase(
				fetchInvoiceableJobsAction.fulfilled,
				(state, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const invoices = data as IInvoiceableJob[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_INVOICE_STATE
					 */
					const newInvoices =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_INVOICE_STATE);

					/** Fill newInvoices with the fetched data */
					invoices.forEach((invoice, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newInvoices[index] = {
								...newInvoices[index],
								...invoice,
							};
						}
					});

					state.total = total;
					state.data = newInvoices;
					state.aggregateResults = aggregateResults;
					state.loading.invoices = false;
				},
			)
			.addCase(
				fetchInvoiceChecklistAction.fulfilled,
				(state, action: PayloadAction<IChecklistItem[]>) => {
					state.checklistItems = action.payload as IChecklistItem[];
					state.loading.checklist = false;
				},
			)
			.addCase(
				updateInvoiceChecklistStateAction.fulfilled,
				(state, action: PayloadAction<IChecklistItem[]>) => {
					state.loading.checklist = false;
					state.checklistItems = action.payload as IChecklistItem[];
				},
			)
			.addCase(markInvoiceableJobAsCheckedAction.fulfilled, (state) => {
				state.loading.invoices = false;
			})
			.addCase(markInvoiceableJobsAsCheckedAction.fulfilled, (state) => {
				state.loading.invoices = false;
			})
			.addCase(
				markInvoiceableJobAsSentAction.fulfilled,
				(state, action: PayloadAction<number>) => {
					const job = state.data.find((item) => item.id === action.payload);
					if (job) {
						switch (job.status) {
							case JobStatus.INVOICE_GENERATED:
								job.status = JobStatus.INVOICE_SENT;
								break;
							case JobStatus.REINVOICE_GENERATED:
								job.status = JobStatus.REINVOICE_SENT;
								break;
						}
					}
					state.loading.invoices = false;
				},
			)
			.addCase(
				sendInvoiceableJobToReviewAction.fulfilled,
				(state, action: PayloadAction<number>) => {
					state.loading.invoices = false;
					if (state.data.length) {
						state.data = state.data.filter(
							(item) => item.id !== action.payload,
						);
						state.total -= 1;
					}
				},
			)
			.addCase(reInvoiceJobAction.fulfilled, (state) => {
				state.loading.invoices = false;
			})
			.addCase(getPreviewAction.fulfilled, (state) => {
				state.loading.invoices = false;
			})
			.addCase(
				fetchAllChecklistsAction.fulfilled,
				(state, action: PayloadAction<IChecklist[]>) => {
					state.checklists = action.payload as IChecklist[];
					state.loading.checklist = false;
				},
			)
			.addCase(
				fetchAllTemplatesByTypeAction.fulfilled,
				(state, action: PayloadAction<ITemplate[]>) => {
					state.templates = action.payload as ITemplate[];
					state.loading.checklist = false;
				},
			)
			.addCase(
				createInvoiceAction.fulfilled,
				(state, action: PayloadAction<GeneratedInvoiceResponse[]>) => {
					state.generatedInvoices =
						action.payload as GeneratedInvoiceResponse[];
					state.loading.areInvoicesGenerated = false;
				},
			)
			.addCase(
				changeInvoiceStatusAction.fulfilled,
				(state, action: PayloadAction<changeInvoiceStatusActionReturnType>) => {
					const job = state.data.find(
						(item) => item.id === action.payload.parameters.id,
					);
					if (job) {
						job.status = action.payload.parameters.status;
					}
					state.loading.invoices = false;
				},
			)
			/** Rejected */
			.addCase(fetchInvoiceableJobsAction.rejected, (state) => {
				state.loading.checklist = false;
			})
			.addCase(fetchInvoiceChecklistAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(updateInvoiceChecklistStateAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(sendInvoiceableJobToReviewAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(reInvoiceJobAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(getPreviewAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(markInvoiceableJobAsCheckedAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(markInvoiceableJobsAsCheckedAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(markInvoiceableJobAsSentAction.rejected, (state) => {
				state.loading.invoices = false;
			})
			.addCase(fetchAllChecklistsAction.rejected, (state) => {
				state.loading.checklist = false;
			})
			.addCase(fetchAllTemplatesByTypeAction.rejected, (state) => {
				state.loading.checklist = false;
			})
			.addCase(createInvoiceAction.rejected, (state) => {
				state.loading.areInvoicesGenerated = false;
			})
			.addCase(changeInvoiceStatusAction.rejected, (state) => {
				state.loading.invoices = false;
			});
	},
});

/** Reducer */
export const invoicesReducer = invoicesSlice.reducer;

/** Actions */
export const { clearInvoiceableJobsAction } = invoicesSlice.actions;
