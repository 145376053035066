import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	DRIVERS_FEATURE_KEY,
	NEW_DRIVER_STATE,
} from "../../../../common/models/src/lib/constants/driver.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IDocument,
	IEntityDocumentDto,
} from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	DriverState,
	IDriver,
	IDriverRequestDto,
} from "../../../../common/models/src/lib/interfaces/driver.interface";
import type {
	IQualification,
	IQualificationRequestDto,
} from "../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	createDriver,
	createQualification,
	deleteDriver,
	deleteQualification,
	fetchDriverById,
	fetchDrivers,
	fetchNotLinkedDrivers,
	fetchQualificationsByDriverId,
	updateDriver,
	updateQualification,
} from "./drivers-data-access";

const initialState: DriverState = {
	data: [],
	driver: undefined,
	qualifications: [],
	total: 0,
	aggregateResults: null,
	loading: {
		grid: false,
		driver: false,
		attachments: false,
		qualifications: false,
	},
	errors: null,
};

export const fetchDriversAction = createAsyncThunk(
	"drivers/fetchDrivers",
	(params: CelerumQueryParams) => {
		return fetchDrivers(params);
	},
);

export const fetchDriverByIdAction = createAsyncThunk(
	"drivers/fetchDriverById",
	(id: number) => {
		return fetchDriverById(id);
	},
);

export const createDriverAction = createAsyncThunk(
	"drivers/createDriver",
	(driver: IDriverRequestDto) => {
		return createDriver(driver);
	},
);

export const updateDriverAction = createAsyncThunk(
	"drivers/updateDriver",
	(driver: IDriverRequestDto) => {
		return updateDriver(driver);
	},
);

export const deleteDriverAction = createAsyncThunk(
	"drivers/deleteDriver",
	(id: number) => {
		return deleteDriver(id);
	},
);

export const fetchNotLinkedDriversByUserIdAction = createAsyncThunk(
	"drivers/fetchNotLinkedDriversByUserId",
	(userId: string) => {
		return fetchNotLinkedDrivers(userId);
	},
);

export const fetchQualificationsByDriverIdAction = createAsyncThunk(
	"drivers/fetchQualificationsByDriverId",
	(id: number) => {
		return fetchQualificationsByDriverId(id);
	},
);

export const createQualificationAction = createAsyncThunk(
	"drivers/createQualification",
	(qualification: IQualificationRequestDto) => {
		return createQualification(qualification);
	},
);

export const updateQualificationAction = createAsyncThunk(
	"drivers/updateQualification",
	(qualification: IQualificationRequestDto) => {
		return updateQualification(qualification);
	},
);

export const deleteQualificationAction = createAsyncThunk(
	"drivers/deleteQualification",
	(id: number) => {
		return deleteQualification(id);
	},
);

const driversSlice = createSlice({
	name: DRIVERS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearDriversAction: () => initialState,
		attachDocumentsToQualificationAction: (
			state: DriverState,
			action: PayloadAction<IEntityDocumentDto>,
		) => {
			state.qualifications = state.qualifications.map(
				(item: IQualification) => {
					if (item.id === action.payload.entityId) {
						item.documents = [...item.documents, ...action.payload.documents];
					}
					return item;
				},
			);
		},
		deleteQualificationAttachmentAction: (
			state: DriverState,
			action: PayloadAction<number>,
		) => {
			state.qualifications = state.qualifications.map(
				(item: IQualification) => {
					item.documents = item.documents.filter(
						(document: IDocument) => document.id !== action.payload,
					);
					return item;
				},
			);
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchDriversAction.pending, (state: DriverState) => {
				state.loading.grid = true;
			})
			.addCase(fetchDriverByIdAction.pending, (state: DriverState) => {
				state.loading.driver = true;
			})
			.addCase(createDriverAction.pending, (state: DriverState) => {
				state.loading.grid = true;
			})
			.addCase(updateDriverAction.pending, (state: DriverState) => {
				state.loading.driver = true;
			})
			.addCase(deleteDriverAction.pending, (state: DriverState) => {
				state.loading.grid = true;
			})
			.addCase(
				fetchQualificationsByDriverIdAction.pending,
				(state: DriverState) => {
					state.loading.qualifications = true;
				},
			)
			.addCase(createQualificationAction.pending, (state: DriverState) => {
				state.loading.qualifications = true;
			})
			.addCase(updateQualificationAction.pending, (state: DriverState) => {
				state.loading.qualifications = true;
			})
			.addCase(deleteQualificationAction.pending, (state: DriverState) => {
				state.loading.qualifications = true;
			})
			.addCase(
				fetchNotLinkedDriversByUserIdAction.pending,
				(state: DriverState) => {
					state.loading.grid = true;
				},
			)
			/** Fulfilled */
			.addCase(
				fetchDriversAction.fulfilled,
				(state: DriverState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;

					const drivers = data as IDriver[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_CUSTOMER_STATE
					 */
					const newDrivers =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_DRIVER_STATE);

					/** Fill newCustomers with the fetched data */
					drivers.forEach((driver, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newDrivers[index] = {
								...newDrivers[index],
								...driver,
							};
						}
					});

					state.total = total;
					state.data = newDrivers;
					state.aggregateResults = aggregateResults;
					state.loading.grid = false;
				},
			)
			.addCase(
				fetchDriverByIdAction.fulfilled,
				(state: DriverState, action: PayloadAction<IDriver>) => {
					state.driver = action.payload as IDriver;
					state.loading.driver = false;
				},
			)
			.addCase(
				createDriverAction.fulfilled,
				(state: DriverState, action: PayloadAction<IDriver>) => {
					state.data = [
						{
							...action.payload,
							fullName: `${action.payload.firstName} ${action.payload.lastName}`,
						},
						...state.data,
					];
					state.loading.grid = false;
					state.total += 1;
				},
			)
			.addCase(
				updateDriverAction.fulfilled,
				(state: DriverState, action: PayloadAction<IDriver>) => {
					state.driver = action.payload as IDriver;
					state.loading.driver = false;
				},
			)
			.addCase(
				deleteDriverAction.fulfilled,
				(state: DriverState, action: PayloadAction<number>) => {
					state.data = state.data.filter(
						(driver) => driver.id !== action.payload,
					);
					state.loading.grid = false;
					state.total -= 1;
				},
			)
			.addCase(
				fetchQualificationsByDriverIdAction.fulfilled,
				(state: DriverState, action: PayloadAction<IQualification[]>) => {
					state.qualifications = action.payload as IQualification[];
					state.loading.qualifications = false;
				},
			)
			.addCase(
				createQualificationAction.fulfilled,
				(state: DriverState, action: PayloadAction<IQualification>) => {
					state.qualifications = [...state.qualifications, action.payload];
					state.loading.qualifications = false;
				},
			)
			.addCase(
				updateQualificationAction.fulfilled,
				(state: DriverState, action: PayloadAction<IQualification>) => {
					state.qualifications = state.qualifications.map((qualification) => {
						if (qualification.id === action.payload.id) {
							return action.payload;
						}
						return qualification;
					});
					state.loading.qualifications = false;
				},
			)
			.addCase(
				deleteQualificationAction.fulfilled,
				(state: DriverState, action: PayloadAction<number>) => {
					state.qualifications = state.qualifications.filter(
						(qualification) => qualification.id !== action.payload,
					);
					state.loading.qualifications = false;
				},
			)
			.addCase(
				fetchNotLinkedDriversByUserIdAction.fulfilled,
				(state: DriverState) => {
					state.loading.grid = false;
				},
			)
			/** Rejected */
			.addCase(fetchDriversAction.rejected, (state: DriverState) => {
				state.loading.grid = false;
			})
			.addCase(fetchDriverByIdAction.rejected, (state: DriverState) => {
				state.loading.driver = false;
			})
			.addCase(createDriverAction.rejected, (state: DriverState) => {
				state.loading.grid = false;
			})
			.addCase(updateDriverAction.rejected, (state: DriverState) => {
				state.loading.driver = false;
			})
			.addCase(deleteDriverAction.rejected, (state: DriverState) => {
				state.loading.grid = false;
			})
			.addCase(
				fetchQualificationsByDriverIdAction.rejected,
				(state: DriverState) => {
					state.loading.qualifications = false;
				},
			)
			.addCase(createQualificationAction.rejected, (state: DriverState) => {
				state.loading.qualifications = false;
			})
			.addCase(updateQualificationAction.rejected, (state: DriverState) => {
				state.loading.qualifications = false;
			})
			.addCase(deleteQualificationAction.rejected, (state: DriverState) => {
				state.loading.qualifications = false;
			})
			.addCase(
				fetchNotLinkedDriversByUserIdAction.rejected,
				(state: DriverState) => {
					state.loading.grid = false;
				},
			);
	},
});

/** Reducer */
export const driversReducer = driversSlice.reducer;

/** Actions */
export const {
	clearDriversAction,
	attachDocumentsToQualificationAction,
	deleteQualificationAttachmentAction,
} = driversSlice.actions;
