import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	LOADS_FEATURE_KEY,
	NEW_LOAD_STATE,
} from "../../../../common/models/src/lib/constants/load.constants";
import { LoadStatus } from "../../../../common/models/src/lib/enums/load.enum";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { IDocument } from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	ILoad,
	ILoadDetails,
	ILoadRequestDto,
	LoadState,
} from "../../../../common/models/src/lib/interfaces/load.interface";
import type { ILocation } from "../../../../common/models/src/lib/interfaces/location.interface";
import {
	cancelLoad,
	createLoad,
	deleteLoad,
	fetchLoadById,
	fetchLoads,
	generateManifest,
	generatePrintAllDocuments,
	getClearCustomsAttachments,
	pauseLoad,
	resumeLoad,
	updateLoad,
} from "./loads-data-access";

const initialState: LoadState = {
	data: [],
	clearCustomsDocuments: [],
	load: undefined,
	total: 0,
	aggregateResults: null,
	loading: { grid: false, load: false, status: false },
	errors: null,
};

export const fetchLoadsAction = createAsyncThunk(
	"loads/fetchLoads",
	async (params: CelerumQueryParams) => {
		return fetchLoads(params);
	},
);

export const fetchLoadByIdAction = createAsyncThunk(
	"loads/fetchLoadById",
	async (loadId: number) => {
		return fetchLoadById(loadId);
	},
);

export const fetchLoadStatusAction = createAsyncThunk(
	"loads/fetchLoadStatus",
	async (loadId: number) => {
		return fetchLoadById(loadId);
	},
);

export const createLoadAction = createAsyncThunk(
	"loads/createLoad",
	async (load: ILoadRequestDto) => {
		return createLoad(load);
	},
);

export const updateLoadAction = createAsyncThunk(
	"loads/updateLoad",
	async (load: ILoadRequestDto) => {
		return updateLoad(load);
	},
);

export const pauseLoadAction = createAsyncThunk(
	"loads/pauseLoad",
	(loadId: number) => {
		return pauseLoad(loadId);
	},
);

export const cancelLoadAction = createAsyncThunk(
	"loads/cancelLoad",
	(loadId: number) => {
		return cancelLoad(loadId);
	},
);

export const deleteLoadAction = createAsyncThunk(
	"loads/deleteLoad",
	(loadId: number) => {
		return deleteLoad(loadId);
	},
);

export const resumeLoadAction = createAsyncThunk(
	"loads/resumeLoad",
	(loadId: number) => {
		return resumeLoad(loadId);
	},
);

export const generateManifestAction = createAsyncThunk(
	"loads/generateManifest",
	(loadId: number) => {
		return generateManifest(loadId);
	},
);

export const generatePrintAllDocumentsAction = createAsyncThunk(
	"loads/generatePrintAllDocuments",
	(loadId: number) => {
		return generatePrintAllDocuments(loadId);
	},
);

export const getClearCustomsAttachmentsAction = createAsyncThunk(
	"loads/getClearCustomsAttachmentsAction",
	(loadId: number) => {
		return getClearCustomsAttachments(loadId);
	},
);

const loadsSlice = createSlice({
	name: LOADS_FEATURE_KEY,
	initialState,
	reducers: {
		clearLoadsAction: (state: LoadState) => {
			state.data = initialState.data;
		},
		attachDocumentsToLoadAction: (
			state: LoadState,
			action: PayloadAction<IDocument[]>,
		) => {
			if (state.load) {
				state.load.documents = [
					...state.load.documents,
					...(action.payload as IDocument[]),
				];
			}
		},
		clearLoadAction: (state: LoadState) => {
			state.load = initialState.load;
		},
		deleteDocumentFromLoadAction: (
			state: LoadState,
			action: PayloadAction<number | string | null>,
		) => {
			if (state.load?.documents) {
				state.load.documents = state.load.documents.filter(
					(document) => document.id !== action.payload,
				);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchLoadsAction.pending, (state) => {
				state.loading.grid = true;
			})
			.addCase(fetchLoadByIdAction.pending, (state) => {
				state.loading.load = true;
			})
			.addCase(fetchLoadStatusAction.pending, (state: LoadState) => {
				state.loading.status = true;
			})
			.addCase(createLoadAction.pending, (state) => {
				state.loading.grid = true;
			})
			.addCase(updateLoadAction.pending, (state) => {
				state.loading.load = true;
			})
			.addCase(pauseLoadAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(cancelLoadAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(deleteLoadAction.pending, (state: LoadState) => {
				state.loading.grid = true;
			})
			.addCase(resumeLoadAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(generateManifestAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(generatePrintAllDocumentsAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(getClearCustomsAttachmentsAction.pending, (state: LoadState) => {
				if (state.load) {
					state.loading.load = true;
				} else {
					state.loading.grid = true;
				}
			})
			/** Fulfilled */
			.addCase(
				fetchLoadsAction.fulfilled,
				(state, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const loads = data as ILoad[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_JOB_STATE
					 */
					const newLoads =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_LOAD_STATE);

					/** Fill newLoads with the fetched data */
					loads.forEach((load, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newLoads[index] = {
								...newLoads[index],
								...load,
							};
						}
					});

					state.data = newLoads;
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading.grid = false;
				},
			)
			.addCase(
				generateManifestAction.fulfilled,
				(state: LoadState, action: PayloadAction<Blob>) => {
					if (state.load) {
						state.loading.load = false;
					} else {
						state.loading.grid = false;
					}
					const data = action.payload;
					window.open(URL.createObjectURL(data));
				},
			)
			.addCase(
				generatePrintAllDocumentsAction.fulfilled,
				(state: LoadState, action: PayloadAction<Blob>) => {
					if (state.load) {
						state.loading.load = false;
					} else {
						state.loading.grid = false;
					}
					const data = action.payload;
					window.open(URL.createObjectURL(data));
				},
			)
			.addCase(
				fetchLoadByIdAction.fulfilled,
				(state, action: PayloadAction<ILoadDetails>) => {
					const startLocation: ILocation = {
						...action.payload.startLocation,
						nameAndAddress: `${action.payload.startLocation.name} - ${action.payload.startLocation.address}`,
					};

					const endLocation: ILocation | undefined = action.payload
						?.endLocation && {
						...action.payload.endLocation,
						nameAndAddress: `${action.payload.endLocation.name} - ${action.payload.endLocation.address}`,
					};

					state.load = { ...action.payload, startLocation, endLocation };
					state.loading.load = false;
				},
			)
			.addCase(
				fetchLoadStatusAction.fulfilled,
				(state: LoadState, action: PayloadAction<ILoadDetails>) => {
					if (state.load) {
						state.load.status = action.payload.status;
						state.loading.status = false;
					}
				},
			)
			.addCase(
				createLoadAction.fulfilled,
				(state, action: PayloadAction<ILoad>) => {
					state.data = [action.payload, ...state.data];
					state.total += 1;
					state.loading.grid = false;
				},
			)
			.addCase(
				updateLoadAction.fulfilled,
				(state, action: PayloadAction<ILoadDetails>) => {
					const startLocation: ILocation = {
						...action.payload.startLocation,
						nameAndAddress: `${action.payload.startLocation.name} - ${action.payload.startLocation.address}`,
					};

					const endLocation: ILocation | undefined = action.payload
						?.endLocation && {
						...action.payload.endLocation,
						nameAndAddress: `${action.payload.endLocation.name} - ${action.payload.endLocation.address}`,
					};

					state.load = { ...action.payload, startLocation, endLocation };
					state.loading.load = false;
				},
			)
			.addCase(
				pauseLoadAction.fulfilled,
				(state: LoadState, action: PayloadAction<number>) => {
					if (state.load) {
						state.load.status = LoadStatus.Paused;
						state.loading.load = false;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const load = state.data[index];
						if (load != null) load.status = LoadStatus.Paused;
					}
				},
			)
			.addCase(
				resumeLoadAction.fulfilled,
				(state: LoadState, action: PayloadAction<number>) => {
					if (state.load) {
						state.loading.load = false;
						state.load.status = LoadStatus.Planned;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const load = state.data[index];
						if (load != null) load.status = LoadStatus.Planned;
					}
				},
			)
			.addCase(
				cancelLoadAction.fulfilled,
				(state: LoadState, action: PayloadAction<number>) => {
					if (state.load) {
						state.loading.load = false;
						state.load.status = LoadStatus.Cancelled;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const load = state.data[index];
						if (load != null) load.status = LoadStatus.Cancelled;
						state.data = state.data.filter(
							(item) => item.id !== action.payload,
						);
					}
				},
			)
			.addCase(
				deleteLoadAction.fulfilled,
				(state: LoadState, action: PayloadAction<number | null>) => {
					state.loading.grid = false;
					state.data = state.data.filter((load) => load.id !== action.payload);
				},
			)
			.addCase(
				getClearCustomsAttachmentsAction.fulfilled,
				(state: LoadState, action: PayloadAction<IDocument[]>) => {
					state.loading.load = false;
					state.clearCustomsDocuments = action.payload;
				},
			)
			/** Rejected */
			.addCase(fetchLoadsAction.rejected, (state: LoadState) => {
				state.loading.grid = false;
			})
			.addCase(fetchLoadByIdAction.rejected, (state: LoadState) => {
				state.loading.load = false;
			})
			.addCase(fetchLoadStatusAction.rejected, (state: LoadState) => {
				state.loading.status = false;
			})
			.addCase(createLoadAction.rejected, (state: LoadState) => {
				state.loading.grid = false;
			})
			.addCase(updateLoadAction.rejected, (state: LoadState) => {
				state.loading.load = false;
			})
			.addCase(pauseLoadAction.rejected, (state: LoadState) => {
				if (state.load) {
					state.loading.load = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(cancelLoadAction.rejected, (state: LoadState) => {
				if (state.load) {
					state.loading.load = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(deleteLoadAction.rejected, (state: LoadState) => {
				state.loading.grid = false;
			})
			.addCase(generateManifestAction.rejected, (state: LoadState) => {
				if (state.load) {
					state.loading.load = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(generatePrintAllDocumentsAction.rejected, (state: LoadState) => {
				if (state.load) {
					state.loading.load = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(resumeLoadAction.rejected, (state: LoadState) => {
				if (state.load) {
					state.loading.load = false;
				} else {
					state.loading.grid = false;
				}
			});
	},
});

/** Actions */
export const {
	clearLoadsAction,
	attachDocumentsToLoadAction,
	deleteDocumentFromLoadAction,
	clearLoadAction,
} = loadsSlice.actions;

/** Reducer */
export const loadsReducer = loadsSlice.reducer;
