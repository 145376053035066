export const TRUCK_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
	{ field: "registration", title: "Registration Number", sortable: false },
	{
		field: "nextMOTDate",
		title: "Next MOT Date",
		type: "date",
		sortable: false,
	},
	{
		field: "nextInspectionTime",
		title: "Next Inspection Date",
		type: "date",
		sortable: false,
	},
	{
		field: "constraints",
		title: "Constraints Satisfied",
		type: "tags",
		sortable: false,
	},
	{ field: "truckType.name", title: "Type", sortable: false },
	{ field: "subcontractor.name", title: "Subcontractor", sortable: false },
	{ field: "notes", title: "Notes", sortable: false },
];

export const TRUCK_TYPES_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
];

export const TRAILER_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
	{ field: "chassisNo", title: "Chassis No.", sortable: false },
	{ field: "fleetNumber", title: "Fleet No.", sortable: false },
	{ field: "ministryNumber", title: "Ministry No.", sortable: false },
	{
		field: "europeanRegistrationNumber",
		title: "European Registration No.",
		sortable: false,
	},
	{
		field: "nextMOTDate",
		title: "Next MOT Date",
		type: "date",
		sortable: false,
	},
	{
		field: "nextInspectionDate",
		title: "Next Inspection Date ",
		type: "date",
		sortable: false,
	},
	{
		field: "trailerType.name",
		title: "Type",
		filterable: false,
		sortable: false,
	},
	{
		field: "constraints",
		title: "Constraints Satisfied",
		type: "tags",
		sortable: false,
	},
	{ field: "notes", title: "Notes", sortable: false },
];

export const TRAILER_TYPES_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
];

export const JOB_GRID_COLUMNS = [
	{ field: "status", title: "Status", type: "status", sortable: true },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "jobTypeName", title: "Type", sortable: true },
	{ field: "purchaseOrderNumber", title: "PO Number", sortable: true },
	{ field: "customerName", title: "Customer", sortable: true },
	{ field: "startLocationName", title: "Start Location", sortable: true },
	{ field: "endLocationName", title: "End Location", sortable: true },
	{ field: "startDate", title: "Start Date", sortable: true, type: "date" },
	{ field: "endDate", title: "End Date", sortable: true, type: "date" },
	{ field: "price", title: "Price", sortable: true },
	{ field: "underwayLegName", title: "Current Driver", sortable: true },
	{ field: "assignedToName", title: "Assigned To", sortable: true },
];

// For Invoicing check grid we need:
// Select, Status, ID, Customer name, PO number, Price, VAT rate, Total, Currency, POD
export const INVOICING_CHECK_GRID_COLUMNS = [
	{ field: "select", title: "Select", type: "checkbox", sortable: false },
	{ field: "status", title: "Status", type: "status", sortable: true },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "customerName", title: "Customer", sortable: true },
	{ field: "startDate", title: "Start Date", sortable: true, type: "date" },
	{
		field: "purchaseOrderNumber",
		title: "PO Number",
		type: "inputtext",
		sortable: false,
	},
	{ field: "price", title: "Price", type: "inputtext", sortable: false },
	{ field: "vatRate", title: "VAT Rate", sortable: true },
	{ field: "cost", title: "Cost", sortable: true },
	{ field: "grossSum", title: "Total", sortable: true },
	{ field: "podStateString", title: "POD", sortable: false },
	{ field: "assignedToName", title: "Assigned To", sortable: true },
];

export const INVOICE_GRID_COLUMNS = [
	{ field: "select", title: "Select", type: "checkbox", sortable: false },
	{ field: "status", title: "Status", type: "status", sortable: true },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "invoiceNumber", title: "Sage Number", sortable: true },
	{ field: "purchaseOrderNumber", title: "PO Number", sortable: true },
	{ field: "customerName", title: "Customer", sortable: true },
	{ field: "startDate", title: "Start Date" },
	{ field: "price", title: "Price", sortable: true },
	{ field: "vatRate", title: "VAT Rate", sortable: true },
	{ field: "cost", title: "Cost", sortable: true },
	{ field: "grossSum", title: "Total", sortable: true },
	{ field: "invoicePreference", title: "Preference", sortable: false },
	{ field: "assignedToName", title: "Assigned To", sortable: true },
];

export const LOAD_GRID_COLUMNS = [
	{ field: "status", title: "Status", type: "status", sortable: true },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "loadTypeName", title: "Type", sortable: true },
	{ field: "startLocationName", title: "Start Location", sortable: true },
	{ field: "startDate", title: "Start Date", sortable: true },
	{ field: "endLocationName", title: "End Location", sortable: true },
	{ field: "endDate", title: "End Date", sortable: true },
	{ field: "trailerName", title: "Trailer", sortable: true },
	{ field: "truckName", title: "Truck", sortable: true },
	{ field: "assignedToName", title: "Assigned To", sortable: true },
	{ field: "driverName", title: "Driver", sortable: true },
	{ field: "profit", title: "Profit", sortable: true },
];

export const LOAD_TYPES_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
];

export const LOAD_GOODS_GRID_COLUMNS = [
	{ field: "select", title: "Select", type: "checkbox", sortable: false },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "jobTypeName", title: "Job Type", sortable: true },
	{ field: "customerName", title: "Customer", sortable: true },
	{
		field: "constraints",
		title: "Special Requirements",
		type: "tags",
		sortable: false,
	},
	{ field: "name", title: "Goods Description", sortable: true },
	{ field: "quantity", title: "Quantity", sortable: true },
	{ field: "weight", title: "Weight", sortable: true },
	{
		field: "collectionLocationName",
		title: "Collection Location",
		sortable: false,
	},
	{ field: "collectionDate", title: "Collection Date", sortable: true },
	{
		field: "deliveryLocationName",
		title: "Delivery Location",
		sortable: false,
	},
	{ field: "deliveryDate", title: "Delivery Date", sortable: true },
	{ field: "jobStatus", title: "Job Status", type: "status", sortable: true },
];

export const LEG_GRID_COLUMNS = [
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "parentEntityTypeName", title: "Job Type", sortable: true },
	{ field: "type", title: "Leg Type", sortable: true },
	{ field: "customerName", title: "Customer", sortable: true },
	{ field: "assignedToName", title: "Assigned To", sortable: true },
	{
		field: "collectionLocationName",
		title: "Collection Location",
		sortable: true,
	},
	{ field: "collectionDate", title: "Collection Date", sortable: true },
	{ field: "deliveryLocationName", title: "Delivery Location", sortable: true },
	{ field: "deliveryDate", title: "Delivery Date", sortable: true },
	{ field: "underwayLegName", title: "Current Driver", sortable: true },
	{ field: "supplierInvoice", title: "Supplier Invoice", sortable: true },
	{ field: "status", title: "Leg Status", type: "reactive", sortable: true },
];

export const SUPPLIER_INVOICES_GRID_COLUMNS = [
	{ field: "select", title: "Select", type: "checkbox", sortable: false },
	{
		field: "id",
		title: "ID",
		type: "reverseEllipsis",
		sortable: true,
		showAs: "uniqueId",
	},
	{ field: "status", title: "Leg Status", type: "status", sortable: true },
	{ field: "parentEntityTypeName", title: "Job Type", sortable: true },
	{ field: "type", title: "Leg Type", sortable: true },
	{ field: "goodNames", title: "Goods", sortable: false },
	{ field: "cost", title: "Cost", sortable: true },
	{ field: "deliveryLocationName", title: "Delivery Location", sortable: true },
	{ field: "deliveryDate", title: "Delivery Date", sortable: true },
	{ field: "collectionDate", title: "Collection Date", sortable: true },
	{ field: "truckName", title: "Truck", sortable: true },
	{ field: "subcontractorName", title: "Subcontractor", sortable: true },
	{ field: "supplierInvoice", title: "Supplier Invoice", sortable: true },
	{
		field: "supplierInvoiceNumber",
		title: "Supplier Invoice Number",
		sortable: true,
	},
	{
		field: "supplierInvoiceDateStr",
		title: "Supplier Invoice Date",
		sortable: false,
	},
];

export const DRIVER_GRID_COLUMNS = [
	{ field: "fullName", title: "Name", sortable: true },
	{
		field: "licenceExpirationDate",
		title: "Licence Expiration Date",
		type: "date",
		sortable: false,
	},
	{ field: "primaryPhone", title: "Phone number", sortable: false },
	{ field: "primaryEmail", title: "Email", sortable: false },
	{ field: "notes", title: "Notes", sortable: false },
];

export const SUBCONTRACTOR_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
	{ field: "primaryPhone", title: "Primary phone number", sortable: false },
	{ field: "primaryEmail", title: "Primary email", sortable: false },
	{
		field: "primaryTruckRegistrationNumber",
		title: "Primary Truck Registration Number",
		sortable: false,
	},
	{
		field: "constraints",
		title: "Constraints Satisfied",
		type: "tags",
		sortable: false,
	},
	{
		field: "vehicleTypes",
		title: "Vehicle Types",
		type: "tags",
		sortable: false,
	},
	{ field: "notes", title: "Notes", sortable: false },
];

export const CUSTOMER_GRID_COLUMNS = [
	{ field: "status", title: "Status", type: "status" },
	{ field: "name", title: "Name", sortable: true },
	{ field: "address", title: "Address", sortable: true },
	{ field: "primaryPhone", title: "Primary phone number", sortable: false },
	{ field: "primaryEmail", title: "Primary email", sortable: false },
	{ field: "accountCode", title: "Account Code", sortable: false },
	{ field: "vatNumber", title: "VAT number", sortable: false },
	{ field: "checklist.name", title: "Checklist Template", sortable: false },
	{ field: "invoiceTemplate", title: "Invoice Template", sortable: false },
	{ field: "invoiceType", title: "Invoice Type" },
	{ field: "invoiceSentMethod", title: "Invoice Method" },
	{ field: "invoiceFrequency", title: "Invoice Frequency", sortable: false },
	{ field: "currencyCode", title: "Currency", sortable: false },
];

export const CONSTRAINTS_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
];

export const LOCATIONS_GRID_COLUMNS = [
	{ field: "name", title: "Name", sortable: true },
	{ field: "customer.name", title: "Customer", sortable: true },
	{ field: "address", title: "Address", sortable: false },
	{ field: "notes", title: "Notes", sortable: false },
];

export const USER_GRID_COLUMNS = [
	{ field: "status", title: "Status", type: "status", sortable: false },
	{ field: "firstName", title: "First Name", sortable: false },
	{ field: "lastName", title: "Last Name", sortable: false },
	{ field: "email", title: "Email", sortable: false },
	{ field: "role", title: "Role", sortable: false },
];

export const EXCHANGE_RATE_GRID_COLUMNS = [
	{ field: "exchangeRateText", title: "Exchange Rate" },
];
