import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import { DEFAULT_PAGE_SIZE } from "../../../../../../common/models/src/lib/constants/grid.constants";
import { ICON_URL } from "../../../../../../common/models/src/lib/constants/location.constants";
import { CustomerStatus } from "../../../../../../common/models/src/lib/enums/customer.enum";
import type {
	ILocation,
	ILocationRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/location.interface";
import type { LocationFormUnion } from "../../../../../../common/models/src/lib/types/location.type";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import {
	CelerumSubmitButton,
	CelerumWhiteButton,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormInput,
	CelerumFormTextArea,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { CelerumLoader } from "../../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchCustomersAction } from "../../../../../../customers/data-access/src/lib/customers.slice";
import {
	createLocationAction,
	updateLocationAction,
} from "../../../../../data-access/src/lib/locations.slice";
import { useCurrentLocation } from "../../hooks/use-current-location.hook";
import { GoogleMapsSearchBox } from "../google-maps-searchbox/google-maps-searchbox.component";
import styles from "./location-form.module.scss";

interface LocationFormProps<T> {
	selectedLocation?: ILocation;
	addLocationFromAnotherForm?: boolean;
	onClose?: () => void;
	setFormData?: Dispatch<SetStateAction<T | undefined>>;
	fieldToSet?: string;
	setIsParentFormModified?: Dispatch<SetStateAction<boolean>>;
}

export const LocationForm = <T extends LocationFormUnion>({
	onClose,
	selectedLocation,
	addLocationFromAnotherForm = false,
	setFormData,
	fieldToSet,
	setIsParentFormModified,
}: LocationFormProps<T>) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const { isMapLoaded } = useAppSelector((state) => state.userInterface);
	const {
		currentBusinessUnit,
		customers,
		locations: { loading },
		loadingCustomers,
	} = useAppSelector((state) => ({
		currentBusinessUnit: state.authentication.currentBusinessUnit,
		customers: state.customers.data,
		loadingCustomers: state.customers.loading,
		locations: state.locations,
	}));

	const [formState, setFormState] = useState<ILocationRequestDto>({
		id: selectedLocation?.id,
		name: selectedLocation?.name || "",
		address: selectedLocation?.address || "",
		customerId: selectedLocation?.customer?.id,
		latitude: selectedLocation?.latitude,
		longitude: selectedLocation?.longitude,
	});

	const mapIcon = useMemo(
		() => ({
			url: ICON_URL,
			size: new google.maps.Size(71, 71),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(17, 34),
			scaledSize: new google.maps.Size(25, 25),
		}),
		[],
	);

	const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

	const currentLocation = useCurrentLocation(formState);

	const handleSearchCustomersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchCustomersAction,
		extraTypesLists: [[{ id: "status", value: [CustomerStatus.ACTIVE] }]],
	});

	const handleSubmit = async () => {
		const actionPayload: ILocationRequestDto = {
			...formState,
			id: selectedLocation?.id,
		};

		const actionResult = await dispatchWithNotifications({
			action: selectedLocation ? updateLocationAction : createLocationAction,
			payload: actionPayload,
			successMessage: selectedLocation
				? `Location with address ${actionPayload.address} updated successfully.`
				: `Location with address ${actionPayload.address} created successfully.`,
			errorMessage: selectedLocation
				? `Could not update location with address ${actionPayload.address}.`
				: `Could not create location with address ${actionPayload.address}.`,
		});

		if (
			selectedLocation &&
			updateLocationAction.fulfilled.match(actionResult)
		) {
			onClose?.();
		} else if (
			!selectedLocation &&
			createLocationAction.fulfilled.match(actionResult)
		) {
			setFormData &&
				fieldToSet &&
				setFormData((prev: T | undefined) => {
					if (prev) {
						return {
							...prev,
							[fieldToSet]: {
								id: actionResult.payload.id,
								name: actionResult.payload.name,
								address: actionResult.payload.address,
								nameAndAddress: `${actionResult.payload.name} - ${actionResult.payload.address}`,
							},
						};
					}
				});
			setIsParentFormModified?.(true);
			onClose?.();
		}
	};

	const handleMapClick = async (
		event: google.maps.MapMouseEvent,
	): Promise<string> => {
		const { latLng } = event;
		const geocoder = new google.maps.Geocoder();

		/** Return an empty string if latLng is missing  */
		if (!latLng) {
			return "";
		}

		const response = await new Promise<google.maps.GeocoderResult | null>(
			(resolve) => {
				geocoder.geocode({ location: latLng }, (results, status) => {
					if (status === "OK") {
						resolve(results?.[0] ? results[0] : null);
					} else {
						resolve(null);
					}
				});
			},
		);

		/** Return an empty string if response is missing */
		if (!response) {
			return "";
		}

		const { formatted_address, geometry } = response;

		/** Return an empty string if necessary data is missing from the response */
		if (!formatted_address || !geometry) {
			return "";
		}

		const { location } = geometry;

		/** Return an empty string if location data is missing from the geometry */
		if (!location) {
			return "";
		}

		setMarkers([
			new google.maps.Marker({
				icon: mapIcon,
				position: location,
			}),
		]);

		setFormState((prevState) => ({
			...prevState,
			address: formatted_address,
			latitude: location.lat(),
			longitude: location.lng(),
		}));

		return formatted_address;
	};

	const renderMarkers = () => {
		return markers.map((marker) => {
			const position = marker.getPosition();
			if (position) {
				return (
					<Marker
						key={JSON.stringify(marker.getPosition())}
						position={{ lat: position.lat(), lng: position.lng() }}
						icon={mapIcon}
					/>
				);
			}
			return null;
		});
	};

	useEffect(() => {
		if (selectedLocation) {
			setMarkers([
				new google.maps.Marker({
					icon: mapIcon,
					position: {
						lat: selectedLocation.latitude,
						lng: selectedLocation.longitude,
					},
				}),
			]);
		}
	}, [selectedLocation, mapIcon]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchCustomersAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch customers.",
		});
	}, [dispatchWithNotifications]);

	return (
		<Form
			initialValues={selectedLocation}
			onSubmit={handleSubmit}
			render={(formRenderProps) => {
				return (
					<>
						{addLocationFromAnotherForm && (
							<h3 className={styles.subtitle}>Add Location</h3>
						)}
						<FormElement>
							<fieldset className="k-form-fieldset">
								<div className={styles.formColumns}>
									<div>
										<Field
											id="name"
											name="name"
											label="Name"
											onChange={(event) =>
												setFormState((prevState) => ({
													...prevState,
													name: event.value,
												}))
											}
											component={CelerumFormInput}
											maxLength={250}
											validator={requiredValidator}
											required
											focused="true"
										/>
										<CelerumDisabledInput
											value={currentBusinessUnit?.name}
											label="Business Unit"
										/>
										<Field
											id="address"
											name="address"
											label="Address"
											onChange={(event) =>
												setFormState((prevState) => ({
													...prevState,
													address: event.value,
												}))
											}
											component={CelerumFormInput}
											maxLength={250}
											validator={requiredValidator}
											required
										/>
									</div>
									<div>
										<Field
											id="customer"
											dataItemKey="id"
											label="Customer"
											textField="name"
											name="customer"
											loading={loadingCustomers}
											component={CelerumSuggestionsInput}
											data={customers}
											handleSearchChange={handleSearchCustomersChange}
											onChange={(event) =>
												setFormState((prevState) => ({
													...prevState,
													customerId: event.value?.id,
												}))
											}
										/>
										<Field
											id="notes"
											name="notes"
											label="Notes"
											onChange={(event) =>
												setFormState((prevState) => ({
													...prevState,
													notes: event.value,
												}))
											}
											component={CelerumFormTextArea}
											maxLength={250}
											defaultValue={""}
										/>
									</div>
								</div>
							</fieldset>
							<div className={styles.map}>
								{isMapLoaded ? (
									<>
										<span className={styles.map__title}>Location</span>
										<GoogleMap
											mapContainerStyle={{ width: "100%", height: "400px" }}
											center={currentLocation}
											zoom={15}
											onClick={async (event) => {
												const address = await handleMapClick(event);
												formRenderProps.onChange("address", {
													value: address,
												});
											}}
										>
											<GoogleMapsSearchBox
												setAddress={(address: string) => {
													formRenderProps.onChange("address", {
														value: address,
													});
												}}
												setFormState={setFormState}
												markers={markers}
												setMarkers={setMarkers}
											/>
											{renderMarkers()}
										</GoogleMap>
									</>
								) : (
									<div className={styles.map__loader}>
										<CelerumLoader visible />
									</div>
								)}
							</div>
							<div className={styles.buttonsContainer}>
								{addLocationFromAnotherForm && (
									<CelerumWhiteButton
										title="Cancel"
										style={{ marginTop: "10px" }}
										onClick={onClose}
									/>
								)}
								<CelerumSubmitButton
									type="submit"
									disabled={!formRenderProps.allowSubmit || loading}
									title="Submit"
								/>
							</div>
						</FormElement>
					</>
				);
			}}
		/>
	);
};
