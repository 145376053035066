import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type {
	ILoadDetails,
	ILoadRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/load.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormTextArea,
	CelerumFormUploadInput,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import { createLoadAction } from "../../../../../data-access/src/lib/loads.slice";
import styles from "./load-form.module.scss";

interface LoadFormProps {
	onClose: () => void;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
}

export const LoadForm = ({ onClose, setModalSize }: LoadFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();
	const handleInputChange = useInputChange();

	const { users, loadTypes, trailers, locations, loading } = useAppSelector(
		(state) => ({
			users: state.authentication.users,
			loadTypes: state.loadTypes.data,
			trailers: state.trailers.data,
			loading: state.loads.loading.grid,
			locations: state.locations.data,
		}),
	);

	const [loadTypeId, setLoadTypeId] = useState<number>(loadTypes[0]?.id ?? -1);
	const [trailerId, setTrailerId] = useState<number>();

	const [assignedToId, setAssignedToId] = useState<string | number>();
	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [formData, setFormData] = useState<ILoadDetails | undefined>(
		{} as ILoadDetails,
	);
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		const { notes, startDate, endDate, files } = event.values;

		if (!event.isValid) {
			return;
		}

		const formDataDocuments = new FormData();
		if (files?.length) {
			for (const file of files) {
				formDataDocuments.append("files", file.getRawFile?.() || new Blob());
			}
		}

		const requestObject: ILoadRequestDto = {
			loadTypeId,
			trailerId,
			startLocationId: formData?.startLocation?.id || undefined,
			startDate: startDate && toISOStringWithoutTimezoneOffset(startDate),
			endLocationId: formData?.endLocation?.id || undefined,
			endDate: endDate && toISOStringWithoutTimezoneOffset(endDate),
			assignedTo: assignedToId,
			notes,
		};

		const actionResult = await dispatchWithNotifications({
			action: createLoadAction,
			payload: requestObject,
			errorMessage: "Could not create load.",
		});

		if (createLoadAction.fulfilled.match(actionResult)) {
			if (files?.length > 0) {
				const documentsActionResult = await dispatch(
					addAttachmentsAction({
						documents: formDataDocuments,
						entity: AttachmentUsage.Load,
						entityId: actionResult.payload.id,
					}),
				);
				if (addAttachmentsAction.fulfilled.match(documentsActionResult)) {
					onClose();
					navigate(`/loads/${actionResult.payload.id}`, {
						state: { from: window.location.pathname },
					});
				}
			} else {
				onClose();
				navigate(`/loads/${actionResult.payload.id}`, {
					state: { from: window.location.pathname },
				});
			}
		}
	};

	useEffect(() => {
		showLocationForm
			? setModalSize(ModalSize.Medium)
			: setModalSize(ModalSize.Large);
	}, [showLocationForm, setModalSize]);

	const renderLoadForm = () => (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={formData}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<Field
									id="loadType"
									dataItemKey="id"
									label="Load Type"
									textField="name"
									name="loadType"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										handleInputChange(event, "loadType", setFormData);
										setLoadTypeId(event.target.value.id);
									}}
									data={loadTypes}
									validator={requiredValidator}
									required
								/>
								<Field
									id="startLocation"
									dataItemKey="id"
									label="Start Location"
									textField="nameAndAddress"
									name="startLocation"
									component={CelerumSuggestionsInput}
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "startLocation", setFormData);
									}}
									validator={requiredValidator}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("startLocation");
									}}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="startDate"
									name="startDate"
									label="Start Date"
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									onChange={(event) =>
										handleInputChange(event, "startDate", setFormData)
									}
									required
								/>
								<Field
									id="endLocation"
									dataItemKey="id"
									label="End Location"
									textField="nameAndAddress"
									name="endLocation"
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "endLocation", setFormData);
									}}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("endLocation");
									}}
									component={CelerumSuggestionsInput}
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="endDate"
									name="endDate"
									label="End Date"
									component={CelerumFormDateTimePicker}
									onChange={(event) =>
										handleInputChange(event, "endDate", setFormData)
									}
								/>
							</div>
							<div>
								<Field
									id="trailer"
									dataItemKey="id"
									label="Trailer"
									textField="name"
									name="trailer"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										setTrailerId(event.target.value.id);
										handleInputChange(event, "trailer", setFormData);
									}}
									data={trailers}
								/>
								<Field
									id="assignedTo"
									dataItemKey="id"
									label="Assigned To"
									textField="fullName"
									name="assignedToUser"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										setAssignedToId(event.target.value.id);
										handleInputChange(event, "assignedToUser", setFormData);
									}}
									data={users}
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									onChange={(event) =>
										handleInputChange(event, "notes", setFormData)
									}
									type="text"
								/>
								<Field
									label="Upload Documents"
									id="files"
									name="files"
									component={CelerumFormUploadInput}
									onChange={(event) => {
										setFormData((prev: ILoadDetails | undefined) => {
											if (prev) {
												return { ...prev, files: event.value };
											}
										});
									}}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={
							(!isFormModified && !formRenderProps.allowSubmit) || loading
						}
						title="Create Load"
					/>
				</FormElement>
			)}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<ILoadDetails>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderLoadForm() : renderLocationForm();
};
