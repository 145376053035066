import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import { concat } from "lodash";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../attachments/data-access/src/lib/attachments.slice";
import { TRAILER_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../common/models/src/lib/constants/grid.constants";
import { TRAILERS_PAGE_SIZE } from "../../../../common/models/src/lib/constants/trailer.constants";
import { AttachmentUsage } from "../../../../common/models/src/lib/enums/attachment.enum";
import { FilterItemType } from "../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../common/models/src/lib/interfaces/attachment.interface";
import type { IDocument } from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../common/models/src/lib/interfaces/filter.interface";
import type { ITrailerRequestDto } from "../../../../common/models/src/lib/interfaces/trailer.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDocumentsForm } from "../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumFilters } from "../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchConstraintsAction } from "../../../../constraints/data-access/src/lib/constraints.slice";
import { fetchTrailerTypesAction } from "../../../data-access/src/lib/trailer-types.slice";
import {
	attachDocumentsToTrailerAction,
	clearTrailersAction,
	deleteDocumentsFromTrailerAction,
	deleteTrailerAction,
	fetchTrailersAction,
} from "../../../data-access/src/lib/trailers.slice";
import { TrailersForm } from "./components/trailers-form/trailers-form.component";

interface TrailerFiltersState {
	nextMOTDateFilter: IDateFilter[];
	nextInspectionDateFilter: IDateFilter[];
	typesFilter: IFilterItem[];
	constraintsFilter: IFilterItem[];
}

const initialFilterState: TrailerFiltersState = {
	nextMOTDateFilter: [],
	nextInspectionDateFilter: [],
	typesFilter: [],
	constraintsFilter: [],
};

export const TrailersFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector((state) => state.trailers);
	const { data: trailerTypes } = useAppSelector((state) => state.trailerTypes);
	const { data: constraints } = useAppSelector((state) => state.constraints);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
		documents: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
		documents: false,
	});

	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedTrailer, setSelectedTrailer] = useState<
		ITrailerRequestDto | undefined
	>(undefined);
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: TRAILERS_PAGE_SIZE });
	const [filters, setFilters] = useState<string>("");

	const [trailerFilters, setTrailerFilters] =
		useOldLocalStorage<TrailerFiltersState>(
			"trailerFilterState",
			initialFilterState,
		);

	const renderedTrailers = useMemo(
		() =>
			data.map((trailer) => ({
				...trailer,
				nextInspectionDate:
					trailer.nextInspectionDate && new Date(trailer.nextInspectionDate),
				nextMOTDate: trailer.nextMOTDate && new Date(trailer.nextMOTDate),
			})),
		[data],
	);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (trailer: ITrailerRequestDto) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedTrailer({
			...trailer,
			nextInspectionDate:
				trailer.nextInspectionDate && new Date(trailer.nextInspectionDate),
			nextMOTDate: trailer.nextMOTDate && new Date(trailer.nextMOTDate),
		});
	};

	const openDeleteModal = (trailer: ITrailerRequestDto) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedTrailer(trailer);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false, documents: false });
		setSelectedTrailer(undefined);
	};

	const handleDeleteTrailer = () => {
		if (!selectedTrailer) return;

		dispatchWithNotifications({
			action: deleteTrailerAction,
			payload: selectedTrailer.id,
			successMessage: `Trailer named ${selectedTrailer.name} deleted successfully.`,
			errorMessage: "Could not delete trailer.",
		});
		setSelectedTrailer(undefined);
	};

	const openDocumentsModal = (item: ITrailerRequestDto) => {
		setSelectedTrailer(item);
		setShowModal({ ...showModal, documents: true });
	};

	const handleAddAttachmentsForTrailer = async (
		formState: IEntityAttachmentDto,
	) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				errorMessage: "Could not add attachment.",
			});
			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatchWithNotifications({
					action: attachDocumentsToTrailerAction,
					payload: {
						documents: actionResult.payload,
						entityId: formState.entityId,
					},
					errorMessage: "Could not attach documents to trailer.",
					successMessage: "Document attached to trailer.",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteDocumentFromTrailer = async (
		attachmentId: number | string,
	) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				errorMessage: "Could not delete attachment.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatchWithNotifications({
					action: deleteDocumentsFromTrailerAction,
					payload: Number(attachmentId),
					errorMessage: "Could not delete document from trailer.",
					successMessage: "Document deleted from trailer.",
				});
				selectedTrailer &&
					setSelectedTrailer({
						...selectedTrailer,
						documents: selectedTrailer.documents.filter(
							(document: IDocument) => document.id !== attachmentId,
						),
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatchWithNotifications({
			action: fetchAttachmentUriAction,
			payload: attachmentId,
		});
	};

	const clearFilters = () => {
		setTrailerFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / TRAILERS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchTrailersAction,
					payload: { page, pageSize: TRAILERS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch trailers.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearTrailersAction());
		dispatch(
			fetchTrailersAction({
				page: 1,
				pageSize: TRAILERS_PAGE_SIZE,
				sort,
				filters,
			}),
		);
	};

	useEffect(() => {
		const typesFilterLists = [];

		const {
			nextInspectionDateFilter,
			nextMOTDateFilter,
			typesFilter,
			constraintsFilter,
		} = trailerFilters;

		typesFilter.length && typesFilterLists.push(typesFilter);

		const combinedDateFilters = concat(
			nextInspectionDateFilter,
			nextMOTDateFilter,
		);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"name",
				"chassisNo",
				"notes",
				"fleetNumber",
				"ministryNumber",
				"europeanRegistrationNumber",
			],
			combinedDateFilters,
			typesFilterLists,
			constraintsFilter,
		);

		setFilters(filters);

		const hasFilters =
			searchFilter ||
			combinedDateFilters.length ||
			typesFilterLists.length ||
			constraintsFilter;

		const payload = {
			page: 1,
			pageSize: TRAILERS_PAGE_SIZE,
			sort,
			filters: hasFilters ? filters : "",
		};

		dispatchWithNotifications({
			action: fetchTrailersAction,
			payload,
			errorMessage: "Could not fetch trailers.",
		});

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [trailerFilters, searchFilter, sort, dispatchWithNotifications]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTrailerTypesAction,
			payload: {},
			errorMessage: "Could not fetch trailer types.",
		});
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});

		return () => {
			dispatch(clearTrailersAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Trailers"
				numberOfItems={total}
				addButtonName="Add Trailer"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setFilters={setTrailerFilters}
					setSearchFilter={setSearchFilter}
					initialValues={{
						typesFilter: trailerFilters.typesFilter,
						constraintsFilter: trailerFilters.constraintsFilter,
						nextMOTDateFilter: trailerFilters.nextMOTDateFilter,
						nextInspectionDateFilter: trailerFilters.nextInspectionDateFilter,
					}}
					filters={{
						typesFilter: {
							name: "Types",
							column: "trailerType.id",
							values: trailerTypes,
							type: FilterItemType.DROPDOWN,
						},
						constraintsFilter: {
							name: "Constraints",
							column: "constraintIds",
							values: constraints,
							type: FilterItemType.DROPDOWN,
						},
						nextMOTDateFilter: {
							name: "Next MOT Date",
							column: "nextMOTDate",
							type: FilterItemType.DATERANGE,
						},
						nextInspectionDateFilter: {
							name: "Next Inspection Date",
							column: "nextInspectionDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				columns={TRAILER_GRID_COLUMNS}
				data={renderedTrailers}
				total={total}
				page={page}
				setPage={setPage}
				requestSortedData={requestSortedData}
				requestDataIfNeeded={requestDataIfNeeded}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				openDocumentsModal={openDocumentsModal}
			/>
			<CelerumModal
				title={selectedTrailer ? selectedTrailer.name : "Add New Trailer"}
				width={ModalSize.Medium}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<TrailersForm formState={selectedTrailer} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The trailer will be completely removed and cannot be recovered."
				entityName="trailer"
				entityProperty="named"
				entityValue={selectedTrailer?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteTrailer}
				handleClose={closeModal}
			/>
			<CelerumModal
				title={selectedTrailer ? selectedTrailer.name : "Add new documents"}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.documents}
			>
				<CelerumDocumentsForm
					documents={selectedTrailer?.documents || []}
					onClose={closeModal}
					entityId={selectedTrailer?.id}
					entityUsage={AttachmentUsage.Trailer}
					handleAddAttachments={handleAddAttachmentsForTrailer}
					handleDeleteAttachment={handleDeleteDocumentFromTrailer}
					handleDownloadAttachment={handleDownloadAttachment}
				/>
			</CelerumModal>
		</>
	);
};
