import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { Fragment, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useTitle } from "react-use";
import { TypedDropDownButton } from "../TypedDropDownButton";
import type { JobActions } from "../api/JobApi";
import { useAuditDialog } from "../dialogs/useAuditDialog";
import { useJobFormDialog } from "../dialogs/useJobFormDialog";
import { useLocationFormDialog } from "../dialogs/useLocationFormDialog";
import type { JobFormWithDTOProps } from "../forms/JobFormWithDTO";
import { type JobGrid, JobsGrid } from "../grids/JobsGrid";
import {
	NewJobStatus,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	jobStatusNamesAndColors,
	toCurrency,
	toDatetimeString,
	toasted,
} from "../helpers";
import {
	useGenericDateRangePicker,
	useGenericJobStatusFilter,
} from "../helpersReact";

type ActionItem = {
	text?: string;
	action: keyof JobActions;
	data: JobGrid;
};
type StatusItem = ActionItem & {
	status?: NewJobStatus;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} />
);

const useHandleAction = (
	retry: () => void,
	showJobForm: (props?: JobFormWithDTOProps) => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: ActionItem): undefined => {
			switch (item.action) {
				case "allowViewDetails":
					navigate(`/jobs/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "allowEdit":
					showJobForm({
						id: item.data.id,
						onSubmit: () => {
							showJobForm();
							retry();
						},
					});
					break;
				case "allowDuplicate":
					showJobForm({
						id: item.data.id,
						isDuplicate: true,
						onSubmit: () => {
							showJobForm();
							retry();
						},
					});
					break;
				case "allowPause":
					toasted(
						jobApi.job.jobChangeStatusPauseCreate(item.data.id),
						"Pausing Job",
					).then(retry);
					break;
				case "allowSendToInvoice":
					toasted(
						jobApi.job.jobChangeStatusCreate(
							item.data.id,
							NewJobStatus.READY_FOR_INVOICE,
						),
						"Sending to Invoice",
					).then(retry);
					break;
				case "allowCancel":
					toasted(
						jobApi.job.jobChangeStatusCancelCreate(item.data.id),
						"Cancelling Job",
					).then(retry);
					break;
				case "allowGenerateFileFront":
					toasted(
						jobApi.job
							.jobReportFileFrontDetail(item.data.id, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank")),
						"Generating File Front",
					).then(retry);
					break;
				case "allowForceComplete":
					toasted(
						jobApi.job.jobForceCompleteCreate(item.data.id),
						"Forcing Completion",
					).then(retry);
					break;
				case "isInvoiced":
				case "allowBulkLegsStatusChange":
				case "allowAudit": {
					showAuditFor(item.data.id);
					if (item.data.isNew) {
						toasted(
							jobApi.job.jobIsNewUpdate({
								id: item.data.id,
								isNew: false,
							}),
							"Marking as Read",
						).then(retry);
					}
					break;
				}
				case "allowResume": {
					toasted(
						jobApi.job.jobChangeStatusResumeCreate(item.data.id),
						"Resuming Job",
					).then(retry);
					break;
				}
				case "allowMarkAsSent":
					break;
				case "allowPreviewInvoice":
					break;
				case "allowReInvoice":
					break;
				case "allowSendToInvoicingCheck":
					break;
				case "allowSendToOperations":
					break;
				default:
					return item.action;
			}
		},
		[location.pathname, navigate, showJobForm, showAuditFor, retry],
	);
};

const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<JobGrid>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.actions.allowEdit) {
						items.push({
							text: "Edit",
							action: "allowEdit",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowDuplicate) {
						items.push({
							text: "Duplicate",
							action: "allowDuplicate",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowPause) {
						items.push({
							text: "Pause",
							action: "allowPause",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowSendToInvoice) {
						items.push({
							text: "Send to Invoice",
							action: "allowSendToInvoice",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowCancel) {
						items.push({
							text: "Cancel",
							action: "allowCancel",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowResume) {
						items.push({
							text: "Resume",
							action: "allowResume",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowForceComplete) {
						items.push({
							text: "Force Complete",
							action: "allowForceComplete",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateFileFront) {
						items.push({
							text: "Generate File Front",
							action: "allowGenerateFileFront",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowAudit) {
						items.push({
							text: `Audit${dataItem.isNew ? " (Mark as read)" : ""}`,
							action: "allowAudit",
							data: dataItem,
						});
					}
					return (
						<td>
							<Button
								size="small"
								icon="eye"
								title="View Details"
								onClick={() =>
									handleAction({ action: "allowViewDetails", data: dataItem })
								}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								title="More Actions"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};

const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _jobs = useQuery({
		queryKey: [
			"jobApi.job.jobV2List",
			rangeValues.start,
			rangeValues.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.job
				.jobV2List({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const _currencies = useQuery({
		queryKey: ["jobApi.currency.currencyList"],
		queryFn: () => jobApi.currency.currencyLookupList().then((x) => x.data),
		initialData: [],
	});
	const jobs = useMemo(
		() =>
			_jobs.data.map((item) => {
				const startDate = new Date(item.startDate ?? "");
				const endDate = new Date(item.endDate ?? "");
				const job: JobGrid = {
					actions: item.actions,
					assignedToName: item.assignedToName ?? "",
					customerName: item.customerName ?? "",
					endDate,
					endDateString: toDatetimeString(endDate),
					endLocationName: item.endLocationName ?? "",
					id: item.id ?? 0,
					poNumber: item.purchaseOrderNumber ?? "",
					price: item.price ?? undefined,
					cost: item.cost ?? undefined,
					currencyCode: _currencies.data.find((x) => x.id === item.currencyId)
						?.code,
					startDate,
					startDateString: toDatetimeString(startDate),
					startLocationName: item.startLocationName ?? "",
					status: item.status ?? 0,
					statusName: jobStatusNamesAndColors[item.status ?? 0].name ?? "",
					type: item.jobTypeId ?? 0,
					typeName: item.jobTypeName ?? "",
					uniqueId: item.uniqueId ?? "",
					driverName: item.driverName ?? "",
					subcontractorName: item.subcontractorName ?? "",
					isNew: item.isNew,
				};
				return job;
			}),
		[_jobs.data, _currencies.data],
	);
	return { jobs, retry: _jobs.refetch, loading: _jobs.isFetching };
};

const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] = useGenericDateRangePicker("Jobs");
	const [filterStatusEl, statusValues] = useGenericJobStatusFilter("Jobs");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};

export const JobsPage2 = () => {
	useTitle("Jobs");
	const navigate = useNavigate();
	const location = useLocation();
	const [showLocationForm, locationFormDialog] = useLocationFormDialog();
	const [showAuditFor, auditDialog] = useAuditDialog(TableNameType.Job);
	const [showJobForm, jobFormDialog] = useJobFormDialog(showLocationForm);
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const { jobs, retry, loading } = useFetchData(rangeValues, statusValues);
	const handleAction = useHandleAction(retry, showJobForm, showAuditFor);
	const extraColumns = useExtraColumns(handleAction);
	return (
		<>
			{auditDialog}
			{jobFormDialog}
			{locationFormDialog}
			<JobsGrid
				data={jobs}
				extraColumns={extraColumns}
				refresh={retry}
				extraFilters={extraFilters}
				extraButtons={
					<Button
						icon="plus"
						themeColor="primary"
						onClick={() =>
							showJobForm({
								onSubmit: (x) => {
									showJobForm();
									retry();
									navigate(`/jobs/${x.id}`, {
										state: { from: location.pathname },
									});
								},
							})
						}
					>
						Add Job
					</Button>
				}
				loading={loading}
				footer={{
					price: (data) => (
						<td>
							{toCurrency(
								_.sumBy(
									data.filter((item) => item.status !== NewJobStatus.CANCELLED),
									"price",
								) || 0,
							)}
						</td>
					),
					cost: (data) => (
						<td>
							{toCurrency(
								_.sumBy(
									data.filter((item) => item.status !== NewJobStatus.CANCELLED),
									"cost",
								) || 0,
							)}
						</td>
					),
				}}
			/>
		</>
	);
};
