import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	GOODS_FEATURE_KEY,
	NEW_GOODS_STATE,
} from "../../../../common/models/src/lib/constants/goods.constants";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	GoodsQueryParams,
	GoodsState,
	IGoodsForLoadRequestDto,
	IGoodsLoadDto,
	IGoodsRequestDto,
	IGoodsResponseDto,
} from "../../../../common/models/src/lib/interfaces/goods.interface";
import {
	addGoodsForLoad,
	createGoods,
	deleteGoods,
	deleteGoodsFromLoad,
	fetchGoodsByJobId,
	fetchGoodsByLoadId,
	fetchGoodsForLoad,
	generateCargoLabel,
	generateCmr,
	updateGoods,
} from "./goods-data-access";

const initialState: GoodsState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchGoodsByJobIdAction = createAsyncThunk(
	"goods/fetchGoodsByJobId",
	(jobId: number) => {
		return fetchGoodsByJobId(jobId);
	},
);

export const fetchGoodsByLoadIdAction = createAsyncThunk(
	"goods/fetchGoodsByLoadId",
	(loadId: number) => {
		return fetchGoodsByLoadId(loadId);
	},
);

export const deleteGoodsAction = createAsyncThunk(
	"goods/deleteGoods",
	(id: number) => {
		return deleteGoods(id);
	},
);

export const updateGoodsAction = createAsyncThunk(
	"goods/updateGoods",
	(goods: IGoodsRequestDto) => {
		return updateGoods(goods);
	},
);

export const createGoodsAction = createAsyncThunk(
	"goods/createGoods",
	(goods: IGoodsRequestDto) => {
		return createGoods(goods);
	},
);

export const fetchGoodsForLoadAction = createAsyncThunk(
	"goods/fetchGoodsForLoad",
	(params: GoodsQueryParams) => {
		return fetchGoodsForLoad(params);
	},
);

export const addGoodsForLoadAction = createAsyncThunk(
	"goods/addGoodsForLoad",
	(goodsDto: IGoodsForLoadRequestDto) => {
		return addGoodsForLoad(goodsDto.goods, goodsDto.loadId);
	},
);

export const deleteGoodsFromLoadAction = createAsyncThunk(
	"goods/deleteGoodsFromLoad",
	(dto: IGoodsLoadDto) => {
		return deleteGoodsFromLoad(dto);
	},
);

export const generateCmrAction = createAsyncThunk(
	"goods/generateCmr",
	(goodsId: number) => {
		return generateCmr(goodsId);
	},
);

export const generateCargoLabelAction = createAsyncThunk(
	"goods/generateCargoLabel",
	(goodsId: number) => {
		return generateCargoLabel(goodsId);
	},
);

const goodsSlice = createSlice({
	name: GOODS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearGoodsAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchGoodsByJobIdAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(fetchGoodsByLoadIdAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(deleteGoodsAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(updateGoodsAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(createGoodsAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(fetchGoodsForLoadAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(addGoodsForLoadAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(deleteGoodsFromLoadAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(generateCmrAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			.addCase(generateCargoLabelAction.pending, (state: GoodsState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchGoodsByJobIdAction.fulfilled,
				(state: GoodsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = (data as IGoodsResponseDto[]).map((item) => ({
						...item,
						collectionLocation: item.collectionLocation && {
							...item.collectionLocation,
							nameAndAddress: `${item.collectionLocation.name} - ${item.collectionLocation.address}`,
						},
						deliveryLocation: item.deliveryLocation && {
							...item.deliveryLocation,
							nameAndAddress: `${item.deliveryLocation.name} - ${item.deliveryLocation.address}`,
						},
					}));

					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				fetchGoodsByLoadIdAction.fulfilled,
				(state: GoodsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = (data as IGoodsResponseDto[]).map((item) => ({
						...item,
						collectionLocation: item.collectionLocation && {
							...item.collectionLocation,
							nameAndAddress: `${item.collectionLocation.name} - ${item.collectionLocation.address}`,
						},
						deliveryLocation: item.deliveryLocation && {
							...item.deliveryLocation,
							nameAndAddress: `${item.deliveryLocation.name} - ${item.deliveryLocation.address}`,
						},
					}));
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				deleteGoodsAction.fulfilled,
				(state: GoodsState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(goods) => goods.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			.addCase(
				updateGoodsAction.fulfilled,
				(state: GoodsState, action: PayloadAction<IGoodsResponseDto>) => {
					const index = state.data.findIndex(
						(goods) => goods.id === action.payload.id,
					);
					state.data[index] = {
						...action.payload,
						collectionLocation: action.payload.collectionLocation && {
							...action.payload.collectionLocation,
							nameAndAddress: `${action.payload.collectionLocation.name} - ${action.payload.collectionLocation.address}`,
						},
						deliveryLocation: action.payload.deliveryLocation && {
							...action.payload.deliveryLocation,
							nameAndAddress: `${action.payload.deliveryLocation.name} - ${action.payload.deliveryLocation.address}`,
						},
					};
					state.loading = false;
				},
			)
			.addCase(
				createGoodsAction.fulfilled,
				(state: GoodsState, action: PayloadAction<IGoodsResponseDto>) => {
					const response = {
						...action.payload,
						collectionLocation: action.payload.collectionLocation && {
							...action.payload.collectionLocation,
							nameAndAddress: `${action.payload.collectionLocation.name} - ${action.payload.collectionLocation.address}`,
						},
						deliveryLocation: action.payload.deliveryLocation && {
							...action.payload.deliveryLocation,
							nameAndAddress: `${action.payload.deliveryLocation.name} - ${action.payload.deliveryLocation.address}`,
						},
					};
					state.data = [response, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(addGoodsForLoadAction.fulfilled, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(
				fetchGoodsForLoadAction.fulfilled,
				(state: GoodsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const goods = data as IGoodsResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_GOODS_STATE
					 */

					const newGoods =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_GOODS_STATE);

					/** Fill newGoods with the fetched data */
					goods.forEach((goods, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newGoods[index] = {
								...newGoods[index],
								...goods,
							};
						}
					});

					state.data = newGoods;
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				deleteGoodsFromLoadAction.fulfilled,
				(state: GoodsState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(goods) => goods.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			.addCase(generateCmrAction.fulfilled, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(generateCargoLabelAction.fulfilled, (state: GoodsState) => {
				state.loading = false;
			})
			/** Rejected */
			.addCase(fetchGoodsByJobIdAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(fetchGoodsByLoadIdAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(deleteGoodsAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(updateGoodsAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(createGoodsAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(fetchGoodsForLoadAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(addGoodsForLoadAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(deleteGoodsFromLoadAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(generateCmrAction.rejected, (state: GoodsState) => {
				state.loading = false;
			})
			.addCase(generateCargoLabelAction.rejected, (state: GoodsState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const goodsReducers = goodsSlice.reducer;
