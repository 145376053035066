import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { concat } from "lodash";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUsersByRoleNameAction } from "../../../../../authentication/data-access/src/lib/authentication.slice";
import { LOAD_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_TAKE_SIZE,
} from "../../../../../common/models/src/lib/constants/grid.constants";
import {
	LOADS_PAGE_SIZE,
	LOAD_STATUSES,
	LOAD_STATUS_MAPPINGS,
} from "../../../../../common/models/src/lib/constants/load.constants";
import {
	ADMIN_ROLE,
	OPERATOR_ROLE,
} from "../../../../../common/models/src/lib/constants/user.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import { LoadStatus } from "../../../../../common/models/src/lib/enums/load.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { ILoad } from "../../../../../common/models/src/lib/interfaces/load.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { getFormattedValue } from "../../../../../common/utils/src/lib/helpers/currency.helpers";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchTrailersAction } from "../../../../../trailers/data-access/src/lib/trailers.slice";
import { fetchLoadTypesAction } from "../../../../data-access/src/lib/load-types.slice";
import {
	clearLoadsAction,
	fetchLoadsAction,
} from "../../../../data-access/src/lib/loads.slice";
import { LoadForm } from "../components/load-form/load-form.component";
import {
	canCancel,
	canDelete,
	canDuplicate,
	canGenerateManifest,
	canPause,
	canPrintAllDocuments,
} from "../helpers/load-option.helpers";
import { useLoadActionSelected } from "../hooks/use-load-action-selected.hook";

interface LoadFilterState {
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	loadTypeFilter: IFilterItem[];
	startDateFilter: IDateFilter[];
	endDateFilter: IDateFilter[];
}

const initialFilterState: LoadFilterState = {
	statusFilter: [],
	assignedToFilter: [],
	loadTypeFilter: [],
	startDateFilter: [],
	endDateFilter: [],
};

export const LoadsFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();
	const handleActionSelected = useLoadActionSelected();

	const { data, total, loading } = useAppSelector((state) => state.loads);
	const { users } = useAppSelector((state) => state.authentication);
	const { data: loadTypes } = useAppSelector((state) => state.loadTypes);
	const { currentCurrency: currency } = useAppSelector(
		(state) => state.currencies,
	);

	const [page, setPage] = useState<Page>({ skip: 0, take: LOADS_PAGE_SIZE });
	const [selectedLoadId, setSelectedLoadId] = useState<number | null>(null);
	const [selectedLoadUniqueId, setSelectedLoadUniqueId] = useState<
		string | undefined
	>(undefined);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [showModal, setShowModal] = useState<{
		create: boolean;
		actions: boolean;
	}>({
		create: false,
		actions: false,
	});
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const [loadFilters, setLoadFilters] = useOldLocalStorage<LoadFilterState>(
		"loadFilterState",
		initialFilterState,
	);

	const renderedLoads = useMemo(() => {
		return data.map((load) => {
			const startDate = new Date(load.startDate).toLocaleString();
			const endDate = load.endDate && new Date(load.endDate).toLocaleString();
			const profit = `${getFormattedValue(load.profit || 0, currency?.code)}%`;

			return {
				...load,
				startDate,
				endDate,
				profit,
			};
		});
	}, [currency?.code, data]);

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const renderedLoadTypesList = useMemo(
		() =>
			loadTypes.map((loadType) => {
				return {
					id: loadType.id,
					name: loadType.name,
				};
			}),
		[loadTypes],
	) as IBase[];

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading.grid) {
					return;
				}

				const page = Math.ceil(skip / LOADS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchLoadsAction,
					payload: { page, pageSize: LOADS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch loads!",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearLoadsAction());
		dispatchWithNotifications({
			action: fetchLoadsAction,
			payload: { page: 1, pageSize: LOADS_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch loads!",
		});
	};

	const openCreateModal = () => {
		setShowModal({ ...showModal, create: true });
	};

	const closeModal = () => {
		setShowModal({ ...showModal, create: false, actions: false });
	};

	const canResume = (item: unknown) => {
		const load = item as ILoad;
		return load.status === LoadStatus.Paused;
	};

	const handleMoreOptions = {
		canDelete,
		canCancel,
		canPause,
		canDuplicate,
		canResume,
		canGenerateManifest,
		canPrintAllDocuments,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		const load = data.find((load) => load.id === id);

		setSelectedLoadUniqueId(load?.uniqueId || undefined);
		setSelectedLoadId(id);

		setSelectedAction(CelerumActions[value]);
		setShowModal({ ...showModal, actions: true });
	};

	const clearFilters = () => {
		setLoadFilters(initialFilterState);
	};

	useEffect(() => {
		const typesFilterLists = [];

		loadFilters.statusFilter.length &&
			typesFilterLists.push(loadFilters.statusFilter);
		loadFilters.loadTypeFilter.length &&
			typesFilterLists.push(loadFilters.loadTypeFilter);
		loadFilters.assignedToFilter.length &&
			typesFilterLists.push(loadFilters.assignedToFilter);

		const combinedDateFilters = concat(
			loadFilters.startDateFilter,
			loadFilters.endDateFilter,
		);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"uniqueId",
				"startLocationName",
				"endLocationName",
				"trailerName",
				"truckName",
				"assignedToName",
				"driverName",
			],
			combinedDateFilters,
			typesFilterLists,
		);
		setFilters(filters);

		if (searchFilter || combinedDateFilters.length || typesFilterLists.length) {
			dispatchWithNotifications({
				action: fetchLoadsAction,
				payload: { page: 1, pageSize: LOADS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch loads!",
			});
		} else {
			dispatchWithNotifications({
				action: fetchLoadsAction,
				payload: { page: 1, pageSize: LOADS_PAGE_SIZE },
				errorMessage: "Could not fetch loads!",
			});
		}
		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [dispatchWithNotifications, loadFilters, searchFilter]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchLoadTypesAction,
			errorMessage: "Could not fetch load types!",
		});
		dispatchWithNotifications({
			action: fetchTrailersAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trailers!",
		});
		dispatchWithNotifications({
			action: fetchUsersByRoleNameAction,
			payload: [OPERATOR_ROLE, ADMIN_ROLE],
			errorMessage: "Could not fetch users!",
		});

		return () => {
			dispatch(clearLoadsAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	useEffect(() => {
		if (loading.grid) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.grid]);

	return (
		<>
			<CelerumGridHeader
				title="Loads"
				numberOfItems={total}
				addButtonName="Create Load"
				addButtonDisabled={loading.grid}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setSearchFilter={setSearchFilter}
					setFilters={setLoadFilters}
					initialValues={{
						statusFilter: loadFilters.statusFilter,
						loadTypeFilter: loadFilters.loadTypeFilter,
						assignedToFilter: loadFilters.assignedToFilter,
						startDateFilter: loadFilters.startDateFilter,
						endDateFilter: loadFilters.endDateFilter,
					}}
					filters={{
						statusFilter: {
							name: "Status",
							column: "status",
							values: LOAD_STATUSES,
							type: FilterItemType.DROPDOWN,
						},
						loadTypeFilter: {
							name: "Load Type",
							column: "loadTypeId",
							values: renderedLoadTypesList,
							type: FilterItemType.DROPDOWN,
						},
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						startDateFilter: {
							name: "Start Date",
							column: "startDate",
							type: FilterItemType.DATERANGE,
						},
						endDateFilter: {
							name: "End Date",
							column: "endDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={LOAD_GRID_COLUMNS}
				data={renderedLoads}
				total={total}
				loading={loading.grid}
				commandCellType={1}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleNavigate={(id) =>
					navigate(`${id}`, { state: { from: window.location.pathname } })
				}
				handleActionSelected={handleMoreOptionsSelected}
				handleMoreOptions={handleMoreOptions}
				statusMappings={LOAD_STATUS_MAPPINGS}
			/>
			<CelerumModal
				title="Create Load"
				width={modalSize}
				toggleDialog={closeModal}
				visible={showModal.create}
			>
				<LoadForm onClose={closeModal} setModalSize={setModalSize} />
			</CelerumModal>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this load?`
				}
				entityName="load"
				entityProperty="with ID"
				entityValue={selectedLoadUniqueId}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The load cannot be recovered"
						: "This action can be reverted afterwards."
				}
				isOpen={showModal.actions}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={() => {
					handleActionSelected(
						selectedAction,
						selectedLoadId,
						selectedLoadUniqueId,
					);
					closeModal();
				}}
				handleClose={closeModal}
			/>
		</>
	);
};
